import React, { useContext, useEffect, useState } from "react";
import styles from "./dashboard.module.scss";
import { Progress } from "antd";
import { ContextApi } from "../../contexts";
import Filters from "../../libs/Filters";
import { mainScreemDetails } from "../../services/requests/main";
import { useExtractChildren } from "../../hooks/useExtractChildren";
import moment from "moment";
//@ts-ignore
import PlanoImage from "../../assets/imgs/BANNER-PLANO-NPAC.png";
type MainScreemData = {
  userBalance: {
    _id: string;
    userId: string;
    money: number;
    virtualCurrency: number;
    __v: number;
  };
  levelInfo: {
    currentLevel: {
      position: string;
      start: number;
      max: number;
    };
    nextLevel: {
      position: string;
      start: number;
      max: number;
      amountToNextLevel: string;
    };
  };
  total: number;
  month: number;
};

const Dashboard: React.FC = () => {
  const {
    user,
    spheresResp,
    getAllCommissionsByUserId,
    getSpheresByUser,
    career,
    getAllCareer,
    commissions,
  } = useContext(ContextApi);
  const children = useExtractChildren(spheresResp?.rootNode);
  const [apiData, setApiData] = useState<MainScreemData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      getAllCommissionsByUserId(user._id);
      getAllCareer();
    }
  }, [user, getAllCommissionsByUserId]);

  useEffect(() => {
    if (user) {
      mainScreemDetails(user._id)
        .then((response: any) => {
          return setApiData(response.data as any);
        })
        .catch((error) => {
          console.error("Erro ao buscar dados", error);
        })
        .finally(() => setLoading(false));
      getSpheresByUser(user._id);
    }
  }, [user, getAllCommissionsByUserId]);

  const calculateProgress = () => {
    const generatedAuffs = career?.totalAuffs || 0;
    const nextLevelAuffs = career?.careerNextLevelPoints || 0;
    const totalSum = (generatedAuffs / nextLevelAuffs) * 100;
    return totalSum;
  };

  function formatCountdown() {
    const now = moment();
    let targetDate = now.clone().startOf("month").add(8, "days");
    if (now.isAfter(targetDate)) {
      targetDate.add(1, "month");
    }
    const diff = targetDate.diff(now);
    const duration = moment.duration(diff);

    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();

    return `${days} dias, ${hours} horas e ${minutes} minutos`;
  }

  const formattedCountdown = formatCountdown();

  return (
    <>
      {" "}
      {!loading ? (
        <section className={styles.dashBoardPage}>
          {career && (
            <div className={styles.dashBoardPageAuff}>
              <h2>AUFFS Gerados</h2>

              <div className={styles.dashBoardPageAuffProgress}>
                <span>{apiData?.userBalance?.virtualCurrency?.toString()}</span>
                <Progress
                  percent={calculateProgress()}
                  showInfo={false}
                  trailColor="#F2F2F2"
                  strokeColor="#F04E23"
                  size={["100%", 12]}
                  className={styles.progress}
                />
                <div className={styles.dashBoardPageAuffGift}>
                  <span>
                    {(career?.careerNextLevelPoints || 0) -
                      (career?.totalAuffs || 0)}
                  </span>
                </div>
              </div>

              <div className={styles.dashBoardPageAuffLevel}>
                <div className={styles.dashBoardPageAuffLevelContainer}>
                  <h3>Nível Atual</h3>
                  <div className={styles.dashBoardPageAuffPointsContainer}>
                    <span>{career?.totalAuffs} AUFFS Atuais</span>
                    <span>{career?.careerLevel}</span>
                  </div>
                </div>

                <div className={styles.dashBoardPageAuffLevelContainer}>
                  <h3>Próximo Nível</h3>
                  <div className={styles.dashBoardPageAuffPointsContainer}>
                    <span>
                      {(career?.careerNextLevelPoints || 0) -
                        (career?.totalAuffs || 0)}{" "}
                      AUFFS Restantes
                    </span>
                    <span>{career?.careerNextLevel}</span>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={styles.dashBoardPageTotals}>
            <div className={styles.dashBoardPageTotalsInfomation}>
              <div className={styles.dashBoardPageTotalsItem}>
                <h4>Lucro Disponível</h4>
                <span>
                  {Filters.convertMoneyTextMask(commissions?.balance?.money)}
                </span>
              </div>
              <div className={styles.dashBoardPageTotalsItem}>
                <h4>Lucro Total</h4>
                <span>
                  {Filters.convertMoneyTextMask(commissions?.balance?.money)}
                </span>
              </div>
            </div>

            <div className={styles.borderCenterTotals} />

            <div className={styles.dashBoardPageTotalsInfomation}>
              <div className={styles.dashBoardPageTotalsItem}>
                <h4>Grupo</h4>
                <span>{children?.length} Pessoas</span>
              </div>
              <div className={styles.dashBoardPageTotalsItem}>
                <h4>Fim do Mês</h4>
                <span>{formattedCountdown}</span>
              </div>
            </div>
          </div>
          <img
            src={PlanoImage}
            className={styles.dashBoardPageImage}
            alt="plano-bg"
          />
        </section>
      ) : (
        <></>
      )}
    </>
  );
};

export default Dashboard;
