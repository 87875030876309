import { toast } from "react-toastify";
import {
  deleteFile,
  uploadProductImage,
} from "../../../../services/requests/files";
import { EditCrudProduct } from "../../../../contexts";
import { editProduct } from "../../../../services/requests/products";

const editProductRequest = async (product: EditCrudProduct) => {
  try {
    // Remove files
    const removeFilePromises = product.removedFiles.map((file) =>
      deleteFile(file.originalName)
    );
    await Promise.all(removeFilePromises);

    // Upload new files if any
    if (product.newFiles.length > 0) {
      await uploadProductImage(product.id, product.newFiles);
    }
    // Edit product
    await editProduct(product);

    toast.success("Produto editado com sucesso!");
  } catch (error) {
    toast.error("Falha ao editar produto!");
  }
};

export default editProductRequest;
