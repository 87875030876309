import { toast } from "react-toastify";
import Filters from "../../../libs/Filters";

interface Address {
  country: string;
  state: string;
  city: string;
  zip_code: string;
  line_1: string;
  line_2?: string;
}

interface MobilePhone {
  country_code: string;
  area_code: string;
  number: string;
}

interface Customer {
  address: Address;
  phones: {
    mobile_phone: MobilePhone;
  };
  name: string;
  type: string;
  email: string;
  code: string;
  document: string;
  document_type: string;
  password?: string;
}

interface Shipping {
  amount: number;
  description: string;
  recipient_name: string;
  recipient_phone: string;
  address: Address;
}

export interface MetadataShipping {
  id: number;
  name: string;
  price: number;
  company: {
    id: number;
    name: string;
    picture: string;
  };
  shippingValues: {
    width: number;
    height: number;
    length: number;
    weight: number;
  };
}

interface Checkout {
  expires_in: number;
  billing_address_editable: boolean;
  customer_editable: boolean;
  accepted_payment_methods: string[];
  success_url: string;
}

interface Payment {
  amount: number;
  payment_method: string;
  checkout: Checkout;
}

interface Item {
  amount: number;
  description: string;
  quantity: number;
  code: string;
}

export interface FormattedData {
  customer: Customer;
  shipping: Shipping;
  payments: Payment[];
  items: Item[];
  shippingSelected?: MetadataShipping;
  options?:
    | { title: string; value: string }
    | { option: string; value: string };
}

interface Product {
  shippingValues: {
    width: number;
    height: number;
    length: number;
    weight: number;
  };
  price: number;
  description: string;
  _id: string;
  freeShipping?: boolean;
  digitalProduct?: boolean;
  options?: [{ title: string; choices: string[] }];
}

interface Adress {
  uf: string;
  localidade: string;
}

export interface FormDataTransaction {
  shippingSelected: any;
  name: string;
  cpf: string;
  email: string;
  phone: string;
  cep: string;
  logradouro: string;
  numero: string;
  metadataShipping?: MetadataShipping;
  password?: string;
  options?: { option: string; value: string };
  error?: boolean;
}

// Helper function to build the address object
const buildAddress = (
  formData: FormDataTransaction,
  address: Adress
): Address => ({
  country: "BR",
  state: address.uf.toString(),
  city: address.localidade.toString(),
  zip_code: formData.cep.replace("-", ""),
  line_1: formData.logradouro,
  line_2: formData.numero,
});

const validateMandatoryFields = (
  formData: FormDataTransaction,
  mandatoryFields: (keyof FormDataTransaction)[],
  hasOptions: boolean
) => {
  const missingFields = mandatoryFields.filter((field) => !formData[field]);

  if (missingFields.length > 0) {
    toast.error(`Campo(s) obrigatorio(s): ${missingFields.join(", ")}`);
    return false;
  }

  if (hasOptions && !formData.options?.option) {
    toast.error(`Verifique as opcoes e tente novamente`);
    return false;
  }

  return true;
};

const getShippingAmount = (product: Product, formData: FormDataTransaction) =>
  product.freeShipping
    ? 0
    : Math.round(Number(formData?.metadataShipping?.price) * 100);

const buildFormattedData = (
  formData: FormDataTransaction,
  product: Product,
  address: Adress,
  amountShipping: number,
  successUrl: string
): FormattedData => {
  const phoneNumberOnlyNumbers = Filters.clearStringOnlyNumbers(formData.phone);

  return {
    customer: {
      address: buildAddress(formData, address),
      phones: {
        mobile_phone: {
          country_code: "55",
          area_code: phoneNumberOnlyNumbers.substring(0, 2),
          number: phoneNumberOnlyNumbers.substring(2),
        },
      },
      name: formData.name,
      type: "individual",
      email: formData.email,
      code: "0",
      document: formData.cpf.replace(/\D/g, ""),
      document_type: "CPF",
      password: formData.password || "",
    },
    shipping: {
      amount: Math.round(amountShipping) ? Math.round(amountShipping) : 1,
      description: `${formData?.metadataShipping?.id?.toString()} - ${
        formData?.metadataShipping?.name ?? null
      }`,
      recipient_name: formData.name,
      recipient_phone: phoneNumberOnlyNumbers,
      address: buildAddress(formData, address),
    },
    payments: [
      {
        amount: amountShipping
          ? Math.round(product.price * 100 + amountShipping)
          : Math.round(product.price * 100),
        payment_method: "checkout",
        checkout: {
          expires_in: 120,
          billing_address_editable: false,
          customer_editable: true,
          accepted_payment_methods: ["credit_card", "debit_card"],
          success_url: successUrl,
        },
      },
    ],
    items: [
      {
        amount: Math.round(product.price * 100),
        description: product.description.slice(0, 50),
        quantity: 1,
        code: product._id,
      },
    ],
    shippingSelected: !product.freeShipping
      ? formData.metadataShipping
      : undefined,
    options: formData.options || { title: "", value: "" },
  };
};

const formatDataForApi = (
  formData: FormDataTransaction,
  product: Product,
  address: Adress
): FormattedData => {
  const mandatoryFields: (keyof FormDataTransaction)[] = [
    "name",
    "cpf",
    "email",
    "phone",
    "cep",
    "logradouro",
    "numero",
  ];

  const hasOptions = product.options?.some((option) => option?.title);

  if (hasOptions) {
    mandatoryFields.push("options");
  }

  if (!validateMandatoryFields(formData, mandatoryFields, hasOptions!)) {
    throw new Error("Campos obrigatórios faltando");
  }

  if (!formData.metadataShipping) {
    throw new Error("Informações de envio não estão disponíveis");
  }

  const location = window.location.href.split("/");
  const successUrl = `${location[0]}//${location[2]}`;

  const amountShipping = getShippingAmount(product, formData);

  return buildFormattedData(
    formData,
    product,
    address,
    amountShipping,
    successUrl
  );
};



export { formatDataForApi };
