const calculateInstallments = (amount: number) => {
    const totalAmountInCents = Math.round(amount * 1000);
    const baseInstallmentAmountInCents = Math.round(totalAmountInCents / 10);

    return new Array(12).fill(null).map((_, index) => {
      let installmentAmountInCents = baseInstallmentAmountInCents;

      if (index >= 1) {
        const increase = Math.round(baseInstallmentAmountInCents * 0.01818);
        installmentAmountInCents += increase * index;
      }

      installmentAmountInCents = Math.round(installmentAmountInCents);

      return {
        number: index + 1,
        total: installmentAmountInCents,
      };
    });
  };

  export {
    calculateInstallments,
  }