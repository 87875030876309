import { createSlice } from "@reduxjs/toolkit";

export const productsRedux = createSlice({
  name: "product",
  initialState: {
    value: [],
    not: [],
  },
  reducers: {
    resetProduct: (state) => {
      state.value = [];
    },

    setProduct: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { resetProduct, setProduct } = productsRedux.actions;

export default productsRedux.reducer;
