import { Divider } from "../divider";
import styles from "./styleDocument.module.scss";
import { Download, Expand, Maximize } from "lucide-react";
import { useCallback, useState } from "react";
import { Modal } from "antd";

interface DocumentProps {
  document: any;
}
export function Documents({ document }: DocumentProps): JSX.Element {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <div className={styles.contentDocument}>
      <div className={styles.boxHeaderDocument}>
        <h4>{document.name}</h4>
        <div className={styles.boxValue}></div>
        <Divider />
        <div className={styles.actions}>
          <div
            className={styles.boxValueAction}
            onClick={() => setOpenModal(!openModal)}
          >
            <p>Visualizar descrição</p>
          </div>
        </div>
        <Modal
          open={openModal}
          onCancel={() => {
            setOpenModal(!openModal);
          }}
          onOk={() => {
            setOpenModal(!openModal);
          }}
        >
          <p>{document.description}</p>
        </Modal>

        <div
          onClick={() => {
            const baseUrl = "https://nyc3.digitaloceanspaces.com/npac-stage/";
            const fullPath = new URL(document.key, baseUrl).href;
            window.open(fullPath, "_blank", "noopener,noreferrer");
          }}
          className={styles.boxValueAction}
        >
          <Download />
          <p>Baixar Documento</p>
        </div>
      </div>
    </div>
  );
}
