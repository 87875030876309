import React, { ChangeEvent, useContext, useState } from "react";
import { User, Lock, Eye, EyeOff } from "lucide-react";
import { ContextApi } from "../../../contexts";
import { LoginLayout } from "../../../components/loginLayout";
import Spin from "antd/es/spin";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./login.module.scss";

type UserType = {
  email: string;
  password: string;
};

const Login: React.FC = () => {
  const { loginRequest } = useContext(ContextApi);

  const [lookPassword, setLookPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState<UserType>({
    email: "",
    password: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    loginRequest(user.email, user.password);
    setTimeout(() => setLoading(false), 500);
  };

  const togglePasswordVisibility = () => {
    setLookPassword(!lookPassword);
  };

  return (
    <Spin
      spinning={loading}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <LoginLayout>
        <div className={styles.pageLoginRegistrationForm}>
          <form onSubmit={handleSubmit}>
            <h2>PLANONPAC</h2>

            <div className={styles.pageLoginRegistrationFormInput}>
              <User
                className={styles.pageLoginRegistrationInputIcon}
                size={20}
                color="#AEAEAE"
              />
              <input
                type="text"
                placeholder="Usuário ou Email"
                onChange={handleChange}
                value={user.email}
                name="email"
              />
            </div>
            <div className={styles.pageLoginRegistrationFormInput}>
              <Lock
                className={styles.pageLoginRegistrationInputIcon}
                size={20}
                color="#AEAEAE"
              />
              <input
                type={lookPassword ? "text" : "password"}
                placeholder="Sua senha"
                onChange={handleChange}
                value={user.password}
                name="password"
              />
              <button
                type="button"
                disabled={loading}
                onClick={togglePasswordVisibility}
              >
                <div style={{ marginTop: "-30px" }}>
                  {lookPassword ? (
                    <EyeOff size={25} color="#DDDD" />
                  ) : (
                    <Eye size={25} color="#DDDD" />
                  )}
                </div>
              </button>
            </div>
            <button type="submit">Entrar</button>
            <p>
              Sou novo por aqui.{" "}
              <a
                href="https://forms.gle/q4XMsEYztVfwDL5K8"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Encontrar parceiro para me auxiliar!</span>
              </a>
            </p>
            <p style={{ marginTop: "2%" }}>
              <a href="/recover-pass" target="_blank" rel="noopener noreferrer">
                <span> Esqueceu a senha?</span>
              </a>
            </p>
          </form>
        </div>
      </LoginLayout>
    </Spin>
  );
};

export default Login;
