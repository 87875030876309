import React, { useState, useCallback } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Space,
  Spin,
  Switch,
  Upload,
  UploadFile,
  FormProps,
  UploadProps,
  Radio,
} from "antd";

import styles from "./stylesAddProducts.module.scss";
import { AddCrudProduct } from "../../../../contexts";
import { Trash, UploadIcon, X } from "lucide-react";
import {
  addProductV2,
  deleteProductV2,
} from "../../../../services/requests/products";
import { toast } from "react-toastify";
import { uploadProductImageV2 } from "../../../../services/requests/files";
import { FieldType, ModalAddProductsProps, SizeType } from "./types";
import { filesHandler, format } from "./formatter";
import { UploadChangeParam } from "antd/es/upload";

const ModalAddProducts: React.FC<ModalAddProductsProps> = ({ close }) => {
  const [formSize, setFormSize] = useState<SizeType | "default">("default");
  const [flags, setFlags] = useState({
    directCommission: false,
    isCademi: false,
    isRecurrent: false,
    addSizeOptions: false,
    isGroupCommission: false,
    isSphereCommission: false,
  });
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleChange: UploadProps["onChange"] = useCallback(
    (info: UploadChangeParam<UploadFile<any>>) => {
      const newFileList = info.fileList.map((file) => {
        if (file.response) {
          file.url = URL.createObjectURL(file.response.file);
        }
        return file;
      });
      setFileList(newFileList);
    },
    []
  );

  const onFormLayoutChange = useCallback(({ size }: { size: SizeType }) => {
    setFormSize(size);
  }, []);

  const handleFinish: FormProps<FieldType>["onFinish"] = useCallback(
    async (values: FieldType) => {
      const payload: AddCrudProduct = format(values, fileList);

      setLoading(true);
      const toastId = toast.loading("Carregando...");

      try {
        const res = await addProductV2(payload);
        const id = res.data._id;

        try {
          await uploadProductImageV2(id, filesHandler(fileList));
          toast.update(toastId, {
            render: "Produto cadastrado com sucesso!",
            type: "success",
            isLoading: false,
            autoClose: 5000,
            closeButton: <X size={16} color="#8B8B8B" />,
            closeOnClick: true,
          });
          close(true);
        } catch {
          await deleteProductV2(id);
          toast.update(toastId, {
            render: "Falha ao cadastrar o produto!",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            closeButton: <X size={16} color="#8B8B8B" />,
            closeOnClick: true,
          });
        }
      } catch {
        toast.update(toastId, {
          render: "Erro ao cadastrar o produto",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeButton: <X size={16} color="#8B8B8B" />,
          closeOnClick: true,
        });
      } finally {
        setLoading(false);
      }
    },
    [close, fileList]
  );

  const handleFinishFailed: FormProps<FieldType>["onFinishFailed"] =
    useCallback((errorInfo: any) => {
      console.log("Failed:", errorInfo);
    }, []);

  const toggleFlag = useCallback(
    (flag: keyof typeof flags) => {
      setFlags((prevFlags) => ({
        ...prevFlags,
        [flag]: !prevFlags[flag],
      }));
    },
    [flags]
  );

  const setCommissionType = useCallback((type: "grupo" | "esfera") => {
    setFlags((prevFlags) => ({
      ...prevFlags,
      isGroupCommission: type === "grupo",
      isSphereCommission: type === "esfera",
    }));
  }, []);

  return (
    <div className={styles.content}>
      <Spin spinning={loading}>
        <Form
          labelCol={{ span: 4.5 }}
          wrapperCol={{ span: 18 }}
          layout="vertical"
          initialValues={{ size: formSize }}
          onValuesChange={onFormLayoutChange}
          size={formSize as SizeType}
          style={{ maxWidth: 600 }}
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
        >
          <Form.Item
            label="Nome"
            name="name"
            rules={[{ required: true, message: "Campo obrigatório!" }]}
          >
            <Input
              style={{ width: "580px", height: "50px" }}
              placeholder="Nome do produto"
            />
          </Form.Item>
          <Form.Item
            label="Descrição"
            name="description"
            rules={[{ required: true, message: "Campo obrigatório!" }]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Descrição do produto"
              style={{ maxWidth: "900px", width: "580px" }}
            />
          </Form.Item>
          <Space.Compact>
            <Form.Item
              label="Preço"
              name="price"
              rules={[{ required: true, message: "Campo obrigatório!" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Valor do produto"
              />
            </Form.Item>
            <Form.Item
              label="AUFFS"
              name="auff"
              rules={[{ required: true, message: "Campo obrigatório!" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Auffs gerados pelo produto"
              />
            </Form.Item>
            <Form.Item
              label="Tipo de produto"
              name="productType"
              style={{ width: "100%" }}
              rules={[
                { required: true, message: "Selecione o tipo do produto!" },
              ]}
            >
              <Radio.Group style={{ width: "110%" }}>
                <Radio value="fisico"> Físico </Radio>
                <Radio value="digital"> Digital </Radio>
                <Radio value="presencial"> Presencial </Radio>
              </Radio.Group>
            </Form.Item>
          </Space.Compact>

          <Space.Compact>
            <Form.Item
              label="Gera usuário?"
              name="createUser"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Opções de tamanho?"
              name="addSizeOptions"
              valuePropName="checked"
            >
              <Switch onChange={() => toggleFlag("addSizeOptions")} />
            </Form.Item>
            <Form.Item
              label="Cobrança recorrente?"
              name="recurrenceType"
              valuePropName="checked"
            >
              <Switch onChange={() => toggleFlag("isRecurrent")} />
            </Form.Item>
          </Space.Compact>
          <Space.Compact>
            <Form.Item
              label="Entrega Cademi?"
              name="isCademi"
              valuePropName="checked"
            >
              <Switch onChange={() => toggleFlag("isCademi")} />
            </Form.Item>

            <Form.Item
              label="Comissão direta?"
              name="isCommissionable"
              valuePropName="checked"
            >
              <Switch onChange={() => toggleFlag("directCommission")} />
            </Form.Item>

            <Form.Item
              label="Frete grátis?"
              name="freeShipping"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Space.Compact>

          <Space.Compact>
            {flags.isRecurrent && (
              <Form.Item
                label="Tipo de recorrência"
                name="recurrence"
                rules={[
                  {
                    required: flags.isRecurrent,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="mensal"> Mensal </Radio>
                  <Radio value="anual"> Anual </Radio>
                </Radio.Group>
              </Form.Item>
            )}

            {flags.isCademi && (
              <Form.Item
                label="Cademi"
                name="cademiKey"
                rules={[
                  { required: flags.isCademi, message: "Campo obrigatório!" },
                ]}
              >
                <Input placeholder="Codigo de liberação cademi" />
              </Form.Item>
            )}
            {flags.directCommission && (
              <Form.Item
                label="Valor da comissão direta"
                name="directCommissionValue"
                rules={[
                  {
                    required: flags.directCommission,
                    message: "Campo obrigatório!",
                  },
                ]}
              >
                <InputNumber placeholder="10" />
              </Form.Item>
            )}
          </Space.Compact>
          <br />
          <Form.Item label="Tipo de comissão" name="commissionType">
            <Radio.Group
              onChange={(e) => {
                const type = e.target.value;
                setCommissionType(type);
              }}
            >
              <Radio value="grupo"> Grupo </Radio>
              <Radio value="esfera"> Esfera </Radio>
            </Radio.Group>
          </Form.Item>
          {flags.isGroupCommission && (
            <>
              {" "}
              <label>Porcentagem da Comissão (Grupo)</label>
              <Space.Compact style={{ marginTop: "2%" }}>
                <Form.Item
                  name="groupValue1"
                  rules={[
                    {
                      required: flags.isGroupCommission,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="groupValue2"
                  rules={[
                    {
                      required: flags.isGroupCommission,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="groupValue3"
                  rules={[
                    {
                      required: flags.isGroupCommission,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Space.Compact>
            </>
          )}
          {flags.isSphereCommission && (
            <>
              <label>Porcentagem da Comissão (Esferas)</label>
              <Space.Compact style={{ marginTop: "2%" }}>
                <Form.Item
                  name="sphereValue1"
                  rules={[
                    {
                      required: flags.isSphereCommission,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="sphereValue2"
                  rules={[
                    {
                      required: flags.isSphereCommission,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="sphereValue3"
                  rules={[
                    {
                      required: flags.isSphereCommission,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Space.Compact>{" "}
            </>
          )}
          <label>Dimensões (Altura, Largura, Comprimento e Peso)</label>
          <Space.Compact style={{ marginTop: "2%" }}>
            <Form.Item name="height">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item name="width">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item name="length">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item name="weight">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Space.Compact>

          <Upload
            listType="picture"
            onChange={handleChange}
            maxCount={10}
            showUploadList={{
              removeIcon: <Trash size={14} className={styles.icon} />,
            }}
            multiple
            fileList={fileList}
            customRequest={(e) => {
              if (e.onSuccess) e.onSuccess(e);
            }}
            accept=".png,.jpg,.jpeg"
          >
            {fileList.length < 10 && (
              <div className={styles.upload}>
                <strong>
                  Clique aqui ou arraste uma imagem para adicionar
                </strong>
                <UploadIcon color="#f04e23" style={{ marginLeft: 10 }} />
              </div>
            )}
          </Upload>
          <Button className={styles.button} htmlType="submit" block>
            Cadastrar produto
          </Button>
        </Form>
      </Spin>
    </div>
  );
};

export default ModalAddProducts;
