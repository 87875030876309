import api from "../api";
import { toast } from "react-toastify";

export async function uploadProductImage(id: string, productImages: File[]) {
  return new Promise((resolve, reject) => {
    const form = new FormData();
    productImages.forEach((file) => {
      form.append("productImages", file);
    });

    api
      .post(`files/upload/product?productId=${id}`, form)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function uploadProductImageV2(id: string, productImages: File[]) {
  const form = new FormData();
  productImages.forEach((file) => {
    form.append("productImages", file);
  });

  return await api.post(`files/upload/product?productId=${id}`, form);
}

export async function uploadAvatarImage(id: string, avatarImage: File) {
  return new Promise((resolve, reject) => {
    const form = new FormData();
    form.append("avatar", avatarImage);

    api
      .post(`files/upload/avatar?id=${id}`, form)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function uploadInvoice(id: string, invoice: File) {
  return new Promise((resolve, reject) => {
    const form = new FormData();
    form.append("document", invoice);

    api
      .post(`files/upload/invoice?id=${id}`, form)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getFilesByFilter(fieldName: string, id: string) {
  return new Promise((resolve, reject) => {
    api
      .get(`files/search/filters?fieldName=${fieldName}&fieldId=${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getProductImages(id: string) {
  return new Promise((resolve, reject) => {
    api
      .get(`files/search/filters?fieldName=productImages&fieldId=${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function deleteFile(name: string) {
  return new Promise((resolve, reject) => {
    api
      .delete(`files/${name}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getDocuments(type: string = "general") {
  return new Promise((resolve, reject) => {
    api
      .get(`files/search/documents/${type}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getDocumentById(documentId: string) {
  return new Promise((resolve, reject) => {
    api
      .get(`files/search/filters?fieldName=document&fieldId=${documentId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function addDocument(
  name: string,
  description: string,
  file: File,
  uploadedBy: string
) {
  return new Promise((resolve, reject) => {
    const form = new FormData();
    form.append("document", file);

    api
      .post(
        `files/upload/document?documentType=GENERAL&name=${name}&description=${description}&uploadedBy=${uploadedBy}`,
        form
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function sendDocuments(
  type: string, //GENERAL or PERSONAL
  name: string,
  description: string,
  file: File,
  uploadedBy: string
) {
  return new Promise((resolve, reject) => {
    const form = new FormData();
    form.append("document", file);

    api
      .post(
        `files/upload/document?documentType=${type}&name=${name}&description=${description}&uploadedBy=${uploadedBy}`,
        form
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getUniqueDocumentById(documentId: string) {
  return new Promise((resolve, reject) => {
    api
      .get(`files/unique/search/${documentId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function updateDocument(
  name: string,
  description: string,
  id: string,
  files?: File[]
) {
  return new Promise((resolve, reject) => {
    const form = new FormData();

    if (files) {
      form.append("document", files[0]);
    }

    api
      .put(
        `files/document/?id=${id}&name=${name}&description=${description}`,
        form
      )

      .then((response) => {
        resolve(response);
        toast.success("Documento alterado!");
      })
      .catch((error) => {
        reject(error);
      });
  });
}
