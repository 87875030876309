import { FormDataTransaction } from "./types";

export const initialState: FormDataTransaction = {
  name: "",
  cpf: "",
  email: "",
  phone: "",
  cep: "",
  logradouro: "",
  numero: "",
  bairro: "",
  complemento: "",
  referencia: "",
  shippingSelected: 0,
  stateSelected: 0,
  citiesSelected: 0,
  metadataShipping: undefined,
  options: { option: "", value: "" },
  error: false,
};
