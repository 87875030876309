import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart, CategoryScale, LinearScale } from "chart.js/auto";

Chart.register(CategoryScale, LinearScale);

interface PieChartProps {
  data: number[];
  labels: string[];
  label: string;
}

const PieChart: React.FC<PieChartProps> = ({ data, labels, label }) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: data,
        backgroundColor: [
          "rgba(75,192,192,0.2)",
          "rgba(255,99,132,0.2)",
          "rgba(255,205,86,0.2)",
        ],
        borderColor: [
          "rgba(75,192,192,1)",
          "rgba(255,99,132,1)",
          "rgba(255,205,86,1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
  };

  return <Pie data={chartData} options={chartOptions} />;
};

export default PieChart;
