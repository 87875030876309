import React from "react";
import ContextProvider from "./contexts";
import RootRoutes from "./routes";
import { Provider } from "react-redux";
import store from "./redux/store";

import "../src/styles/global.scss";

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <ContextProvider>
        <RootRoutes />
      </ContextProvider>
    </Provider>
  );
}

export default App;
