import React from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { Chart, CategoryScale, LinearScale } from "chart.js/auto";

Chart.register(CategoryScale, LinearScale);

interface LineChartProps {
  transactions: any[]; // Replace 'any' with the actual type of your transactions
}

const LineChart: React.FC<LineChartProps> = ({ transactions }) => {
  // Get the number of days in the current month
  const daysInMonth = moment().daysInMonth();

  // Group transactions by day of the month and count the number of transactions for each day
  const transactionsByDay = transactions.reduce((acc, [date, count]) => {
    const dayOfMonth = moment(date).format("D"); // Format to day of the month
    acc[dayOfMonth] = (acc[dayOfMonth] || 0) + count;
    return acc;
  }, {});

  // Generate labels for the LineChart based on the number of days in the current month
  const labels = Array.from({ length: daysInMonth }, (_, index) =>
    (index + 1).toString()
  );
  const data = labels.map((day) => transactionsByDay[day] || 0);

  const lineChartData = {
    labels,
    datasets: [
      {
        label: "Compras por dia",
        data,
        fill: false,
        borderColor: "#4caf50", // You can customize the color
        borderWidth: 2,
        pointBackgroundColor: "#4caf50", // You can customize the color
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Line data={lineChartData} options={chartOptions} />;
};

export default LineChart;
