import api from "../api";

export async function sendActivateUserEmail(id: string) {
  return await api.post(`/email/confirmation/resend?id=${id}`);
}

export async function requestRecoverPassEmail(recoverEmail: string) {
  return await api.post("/auth/recover", {
    email: recoverEmail,
  });
}
