import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { getTransactionsByOrderId } from "../../services/requests/transactions";
import { Result, QRCode } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import ProductDetailsHeader from "../ProductsDetails/domain/ProductDetailsHeader";
import { Button } from "antd/es/radio";

const CheckoutFeedBack = () => {
  const { orderId } = useParams();

  const [timeLeft, setTimeLeft] = useState(7200); // 2 hours in seconds
  const [feedBack, setFeedBack] = useState("processing");
  const [errorHandler, setErrorHandler] = useState("error");
  const [order, setOrder] = useState<any>(undefined);
  const navigate = useNavigate();

  const fetchOrder = async () => {
    if (orderId) {
      try {
        const { data } = await getTransactionsByOrderId(orderId);
        setFeedBack(data?.status);
        setOrder(data);
        if (data?.status === "failed") {
          setErrorHandler(
            data?.charges[0]?.last_transaction?.gateway_response?.errors[0]
              ?.message
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      fetchOrder();
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds: number) => {
    const h = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const m = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const s = (seconds % 60).toString().padStart(2, "0");
    return `${h}:${m}:${s}`;
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(
      order?.charges[0]?.last_transaction?.qr_code_url
    );
    alert("Código copiado para a área de transferência");
  };
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <ProductDetailsHeader title="Plano NPAC" />
        <div className={styles.containerDetails}>
          {feedBack === "processing" && (
            <Result
              style={{ width: "80vw" }}
              status="info"
              title="Seu pagamento está sendo processado!"
            />
          )}

          {feedBack === "paid" && (
            <Result
              style={{ width: "80vw" }}
              status="success"
              title="Seu pagamento foi recebido com sucesso!"
              extra={[
                <Button
                  type="primary"
                  key="console"
                  onClick={() => navigate("/")}
                >
                  Ok
                </Button>,
              ]}
            />
          )}

          {feedBack === "failed" && (
            <Result
              status="error"
              title="Erro ao efetuar pagamento!"
              subTitle={errorHandler}
              extra={[
                <Button
                  type="primary"
                  key="console"
                  onClick={() => navigate(-1)}
                >
                  Voltar
                </Button>,
              ]}
            />
          )}

          {order && order?.charges[0]?.payment_method === "pix" && (
            <div className={styles.pixPaymentContainer}>
              <h2>Pague com Pix</h2>
              <p>
                Seu código expira em{" "}
                <span className={styles.expirationTime}>
                  {formatTime(timeLeft)}
                </span>
                , faça o pagamento dentro do prazo para garantir a sua compra.
              </p>
              <div className={styles.paymentSummary}>
                <span>Valor a pagar</span>
                <span>R$ {order?.amount / 100}</span>
              </div>
              <div className={styles.qrCodeContainer}>
                <QRCode
                  value={order?.charges[0]?.last_transaction?.qr_code}
                  size={256}
                />
                <p className={styles.qrCodeText}>
                  {order?.charges[0]?.last_transaction?.qr_code}
                </p>
              </div>
              <button onClick={handleCopy} className={styles.copyButton}>
                Copiar código
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CheckoutFeedBack;
