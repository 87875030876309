import React, { useContext, useEffect, useMemo, useState } from "react";

import styles from "./styleDocuments.module.scss";
import { Input, Pagination, Space, Table, Tooltip } from "antd";
import { ContextApi } from "../../../contexts";
import { ColumnsType } from "antd/es/table";
import { Edit, Plus, Trash } from "lucide-react";
import { CustomButton } from "../../../components/buttons/customButton";
import { useNavigate } from "react-router-dom";
const { Search } = Input;

interface DataType {
  name: string;
  description: string;
}

const Documents: React.FC = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Define o tamanho da página

  const navigate = useNavigate();
  const {
    getAllDocuments,
    documents: rawDocuments,
    deleteDocument,
    dimensions,
  } = useContext(ContextApi);

  const documents: DataType[] = useMemo(() => {
    return rawDocuments.map((doc: any) => {
      return {
        id: doc._id,
        name: doc.name,
        description: doc.description,
        key: doc.key,
      };
    });
  }, [rawDocuments]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    setCurrentPage(1); // Ao pesquisar, voltamos para a primeira página
  };

  useEffect(() => {
    getAllDocuments();
  }, [getAllDocuments]);

  const filteredDocuments = useMemo(() => {
    // Filtra os documentos com base no termo de pesquisa
    return documents.filter((doc) =>
      doc.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [documents, search]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Ações",
      key: "action",
      render: (data) => {
        return (
          <Space size="middle">
            <Edit
              onClick={() => navigate(`/admin/documents/edit/?id=${data.id}`)}
              className={styles.icon}
            />
            <Tooltip
              placement="top"
              title={
                <div className={styles.tooltip}>
                  Realmente deseja excluir este documento?
                  <CustomButton onClick={() => deleteDocument(data.key)}>
                    Sim
                  </CustomButton>
                </div>
              }
            >
              <Trash className={styles.icon} />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  if (dimensions.width > 768) {
    columns.splice(1, 0, {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    });
  }

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
  };

  const startItemIndex = (currentPage - 1) * pageSize;
  const paginatedDocuments = filteredDocuments.slice(
    startItemIndex,
    startItemIndex + pageSize
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.header}>
          <div className={styles.title}>Documentos</div>
          <Search
            placeholder="Pesquisar"
            allowClear
            value={search}
            onChange={handleSearchChange}
          />
        </div>
        <CustomButton onClick={() => navigate("add")}>
          <div className={styles.button}>
            Adicionar <Plus />
          </div>
        </CustomButton>
      </div>
      <Table
        pagination={false}
        columns={columns}
        dataSource={paginatedDocuments}
      />
      <Pagination
        className={styles.pagination}
        showSizeChanger
        pageSize={pageSize}
        onShowSizeChange={handlePageSizeChange}
        current={currentPage}
        total={filteredDocuments.length}
        onChange={handlePageChange}
      />
    </div>
  );
};

export default Documents;
