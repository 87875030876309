import React, { useEffect, useState } from "react";
import { Products } from "../../components/products/products";

import styles from "./styleProductPage.module.scss";
import { Radio, Spin } from "antd";
import { getProducts } from "../../services/requests/products";
import { InputTextSimple } from "../../components/inputs/simpleText/inputSimpleText";
export function PageProducts(): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState<any>([]);
  const [filteredProducts, setFilteredProducts] = useState<any>([]);
  const [size, setSize] = useState("");

  async function _fetch() {
    await getProducts().then((res: any) => {
      setProducts(res?.data?.products);
      setFilteredProducts(res?.data?.products);
    });
  }

  useEffect(() => {
    _fetch();
    setTimeout(() => setLoading(false), 800);
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSize("");
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    filterProducts(searchTerm);
  };

  const handleFilterChange = (type: string) => {
    setSearch("");
    const filtered = products.filter(
      (product: { digitalProduct: boolean; presentialProduct: boolean }) => {
        if (type === "digital") {
          return product.digitalProduct;
        } else if (type === "presencial") {
          return product.presentialProduct;
        } else if (type === "fisico") {
          return !product.digitalProduct && !product.presentialProduct;
        }
        return true;
      }
    );
    const removeCreateUserItems = filtered.filter(
      (product: any) => !product.createUser
    );
    setFilteredProducts(removeCreateUserItems);
  };

  const filterProducts = (searchTerm: string) => {
    const filtered = products.filter(
      (product: { name: string; digitalProduct: any }) => {
        const matchesSearch = product.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());

        return matchesSearch;
      }
    );
    const removeCreateUserItems = filtered.filter(
      (product: any) => !product.createUser
    );
    setFilteredProducts(removeCreateUserItems);
  };

  return (
    <div className={styles.container}>
      {products && (
        <div className={styles.filterContainer}>
          <div className={styles.filterDisplay}>
            <InputTextSimple
              placeholder="Pesquisar"
              value={search}
              onChange={handleSearchChange}
              name="search"
            />
          </div>
          <div className={styles.filterDisplayButtons}>
            <Radio.Group
              value={size}
              onChange={(e) => {
                setSize(e.target.value);
                handleFilterChange(e.target.value);
              }}
            >
              <Radio.Button value="digital">Digital</Radio.Button>
              <Radio.Button value="fisico">Fisico</Radio.Button>
              <Radio.Button value="presencial">Presencial</Radio.Button>
            </Radio.Group>
          </div>
        </div>
      )}
      <div className={styles.containerProducts}>
        {filteredProducts &&
          filteredProducts
            .filter((product: { createUser: any }) => !product.createUser)
            .map(
              (product: {
                directCommissionValue: number;
                _id: string;
                name: string;
                price: number;
                auff: any;
                imageUrls: string[];
              }) => (
                <Products
                  key={product?._id}
                  name={product?.name}
                  value={product?.price}
                  auffs={product?.auff ?? 0}
                  directCommition={product?.directCommissionValue ?? 0}
                  image={product?.imageUrls[0]}
                  link={product?._id}
                />
              )
            )}
        <Spin spinning={loading} fullscreen />
      </div>
    </div>
  );
}
