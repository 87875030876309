import React, { useState, useEffect } from "react";
import { InputTextSimple } from "../../../components/inputs/simpleText/inputSimpleText";
import styles from "../styles.module.scss";
import { CreditCard, Info } from "lucide-react";
import { InputSimpleSelect } from "../../../components/inputs/simpleSelect/simpleSelectInput";
import { toast } from "react-toastify";
import { Button, Result } from "antd";
import { calculateInstallments } from "./utils/calculators";

interface PaymentComponentProps {
  currentScreen: number;
  changeWidthInput: (
    value: number,
    defaultWidth: string
  ) => React.CSSProperties;
  productInfo: any;
  shippingPrice: any;
  setStep: any;
  setCardDetails: any;
  cardDetails: any;
  paymentMethod: any;
  setPaymentMethod: any;
  installments: any;
  setInstallments: any;
  proceed: any;
  orderId?: string;
  loading: any;
  feedback: string;
  setFeedback: any;
  errorsMessage: string;
}

const PaymentComponent: React.FC<PaymentComponentProps> = ({
  currentScreen,
  changeWidthInput,
  productInfo,
  shippingPrice,
  setStep,
  setCardDetails,
  cardDetails,
  paymentMethod,
  setPaymentMethod,
  installments,
  setInstallments,
  proceed,
  orderId,
  loading,
  feedback,
  setFeedback,
  errorsMessage,
}) => {
  const [installmentOptions, setInstallmentOptions] = useState<
    { number: number; total: number }[]
  >([]);

  const handlePaymentMethodChange = (method: "pix" | "creditCard") => {
    setPaymentMethod(method);
  };
  const [timeLeft, setTimeLeft] = useState(3200);

  const formatCreditCardNumber = (value: string) => {
    return value
      .replace(/\s+/g, "")
      .replace(/[^0-9]/gi, "")
      .replace(/(\d{4})/g, "$1 ")
      .trim();
  };

  const formatExpirationDate = (value: string) => {
    return value
      .replace(/\s+/g, "")
      .replace(/[^0-9]/gi, "")
      .replace(/(\d{2})(\d{2})/, "$1/$2")
      .trim();
  };

  const formatCVC = (value: string) => {
    return value.replace(/\D/g, "").slice(0, 3);
  };

  const handleInputChange = (
    name: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    let formattedValue = value;

    if (name === "creditCardNumber") {
      formattedValue = formatCreditCardNumber(value);
    } else if (name === "expirationDate") {
      formattedValue = formatExpirationDate(value);
    } else if (name === "cvc") {
      formattedValue = formatCVC(value);
    } else if (name === "cardHolderName") {
      formattedValue = value.toUpperCase();
    }

    setCardDetails((prevData: any) => ({
      ...prevData,
      [name]: formattedValue,
    }));
  };

  const handleInstallmentsChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setInstallments(parseInt(e.target.value, 10));
  };

  const handleSubmit = () => {
    if (paymentMethod === "creditCard") {
      // Verificar os dados do cartão
      const { creditCardNumber, expirationDate, cardHolderName, cvc } =
        cardDetails;
      if (!creditCardNumber || !expirationDate || !cardHolderName || !cvc) {
        toast.warning("Por favor, preencha todos os dados do cartão.");
        return;
      }
      console.log(cardDetails, installments);
      // Se os dados estiverem corretos, prosseguir
      toast.info("Dados do cartão verificados.");
    }
    // Prosseguir com o pagamento
    proceed();
  };

  const calculateTotalPrice = () => {
    const totalShippingPrice =
      shippingPrice?.vlrFrete || parseFloat(shippingPrice?.price) || 0;
    return productInfo?.price + totalShippingPrice;
  };

  useEffect(() => {
    const totalPrice = calculateTotalPrice();
    const installments = calculateInstallments(totalPrice);
    setInstallmentOptions(installments);
  }, [productInfo, shippingPrice]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, [feedback]);

  return (
    <div className={styles.paymentContainer}>
      <h2>Confirme e pague</h2>
      <p>O pagamento será processado de forma automática.</p>
      <div className={styles.eventDetails}>
        <div>
          <strong>Nome: {productInfo?.name}</strong>
        </div>
        <div>Descrição: {productInfo?.description}</div>
        <div className={styles.paymentSummary}>
          <span>Valor a pagar</span>
          <span>R$ {calculateTotalPrice()}</span>
        </div>
      </div>
      {feedback === "processing" && (
        <>
          <div className={styles.paymentMethods}>
            <p>Escolha a forma de pagamento</p>
            <button
              className={`${styles.paymentButton} ${
                paymentMethod === "pix" ? styles.selected : ""
              }`}
              onClick={() => handlePaymentMethodChange("pix")}
            >
              <img
                src={require("../../../assets/images/pix.png")}
                alt="Pix"
                style={{ width: "25px" }}
              />
              <span className={styles.buttonText}>Pix</span>
            </button>
            <button
              className={`${styles.paymentButton} ${
                paymentMethod === "creditCard" ? styles.selected : ""
              }`}
              onClick={() => handlePaymentMethodChange("creditCard")}
            >
              <CreditCard color="#FF7E36" />
              <span className={styles.buttonText}>Cartão de crédito</span>
            </button>
          </div>
          {paymentMethod === "creditCard" && (
            <div className={styles.boxInputs}>
              <div className={styles.formLine1}>
                <InputSimpleSelect
                  id="installments"
                  value={installments}
                  onChange={handleInstallmentsChange}
                  optionZero="Selecione as parcelas"
                  style={changeWidthInput(currentScreen, "100%")}
                  data={installmentOptions.map((option) => {
                    return {
                      id: option.number,
                      nome: `${option.number}x de R$ ${(
                        option.total /
                        100 /
                        option.number
                      ).toFixed(2)}`,
                    };
                  })}
                />
              </div>
              <div className={styles.formLine1}>
                <InputTextSimple
                  name="creditCardNumber"
                  placeholder="Número do Cartão"
                  style={changeWidthInput(currentScreen, "100%")}
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="cc-number"
                  value={cardDetails.creditCardNumber}
                  onChange={(e) => handleInputChange("creditCardNumber", e)}
                />
              </div>
              <div className={styles.formLine1}>
                <InputTextSimple
                  name="expirationDate"
                  placeholder="Data de Vencimento (MM/AA)"
                  style={changeWidthInput(currentScreen, "48%")}
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="cc-exp"
                  value={cardDetails.expirationDate}
                  onChange={(e) => handleInputChange("expirationDate", e)}
                />
                <InputTextSimple
                  name="cvc"
                  placeholder="CVC"
                  style={changeWidthInput(currentScreen, "48%")}
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="cc-csc"
                  value={cardDetails.cvc}
                  onChange={(e) => handleInputChange("cvc", e)}
                />
              </div>
              <div className={styles.formLine1}>
                <InputTextSimple
                  name="cardHolderName"
                  placeholder="Nome no Cartão"
                  style={changeWidthInput(currentScreen, "100%")}
                  onPaste={(e) => e.preventDefault()}
                  autoComplete="cc-name"
                  value={cardDetails.cardHolderName}
                  onChange={(e) => handleInputChange("cardHolderName", e)}
                />
              </div>
            </div>
          )}
          <div className={styles.footerButtons}>
            <button onClick={() => setStep("user")}>Voltar</button>
            <button
              onClick={() => {
                setFeedback("startProcess");
                handleSubmit();
              }}
            >
              {paymentMethod === "pix"
                ? "Prosseguir com PIX"
                : `Prosseguir com Cartão de Crédito em ${installments}x`}
            </button>
          </div>
        </>
      )}

      {feedback === "startProcess" && (
        <Result
          icon={<Info size="70" color="#00aaff" />}
          title="Seu pagamento está sendo processado!"
          subTitle="Em alguns minutos você recebera uma resposta!"
        />
      )}

      {feedback === "error" && (
        <Result
          status="403"
          title="Erro ao gerar seu pedido!"
          subTitle={errorsMessage}
          extra={
            <Button
              type="primary"
              onClick={() => {
                setFeedback("processing");
                setStep("user");
              }}
            >
              Voltar e conferir
            </Button>
          }
        />
      )}
    </div>
  );
};

export default PaymentComponent;
