import { createSlice } from "@reduxjs/toolkit";

export const authRedux = createSlice({
  name: "auth",
  initialState: {
    value: [],
    not: [],
  },
  reducers: {
    resetAuth: (state) => {
      state.value = [];
    },

    setUserAuthInfo: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { resetAuth, setUserAuthInfo } = authRedux.actions;

export default authRedux.reducer;
