import { useState, useEffect, useContext } from "react";
import {
  Modal,
  Card,
  Button,
  Input,
  Form,
  message,
  Tag,
  Statistic,
  Select,
} from "antd";
import { getCareerLevels } from "../../services/requests/careerLevels";
import { getWorkers } from "../../services/requests/workers";
import { Divider } from "../divider";
import { Flag, FlagOff, Handshake, ShoppingBag } from "lucide-react";
import { Products } from "../products/products";
import { ContextApi } from "../../contexts";

interface CareerLevel {
  _id: string;
  position: string;
  start: number;
  max: number;
  maxUsage: number;
}

interface WorkersAndCareerModalProps {
  open: boolean;
  onClose: () => void;
  toggleWorkerStatus: (workerId: string) => void;
}

export default function WorkersAndCareerModal({
  open,
  onClose,
  toggleWorkerStatus,
}: WorkersAndCareerModalProps) {
  const { getAllProducts, products } = useContext(ContextApi);
  const [workers, setWorkers] = useState([]);
  const [careerLevels, setCareerLevels] = useState<CareerLevel[]>([]);
  const [editingCareer, setEditingCareer] = useState<string | null>(null);
  const [currentProduct, setCurrentProduct] = useState<any>(null);

  const [form] = Form.useForm();

  const _fetch = async () => {
    try {
      const careerResponse = await getCareerLevels();
      const workerResponse = await getWorkers();
      getAllProducts();
      setCareerLevels(careerResponse?.data || []);
      setWorkers(workerResponse?.data || []);
      console.log(products);
      setCurrentProduct(products[0]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    _fetch();
  }, []);

  const workerNameFormatter = (name: string) => {
    switch (name) {
      case "PaymentAutCheck":
        return "Pagamentos";
      case "UserActiveCheck":
        return "Ativação periódica";
      case "AuffCheck":
        return "Calculo de Carreiras";
      case "ResponsibilityCheck":
        return "Bonus de Responsabilidade";
      default:
        return "Outros";
    }
  };

  const handleEditCareer = (career: CareerLevel) => {
    setEditingCareer(career._id);
    form.setFieldsValue({
      position: career.position,
      start: career.start,
      max: career.max,
      maxUsage: career.maxUsage,
    });
  };

  const handleSaveCareer = async (careerId: string) => {
    try {
      const values = await form.validateFields();
      const updatedCareers = careerLevels.map((career) =>
        career._id === careerId ? { ...career, ...values } : career
      );
      setCareerLevels(updatedCareers);
      setEditingCareer(null);
      message.success("Career level updated successfully!");
    } catch (errInfo) {
      console.error("Save failed:", errInfo);
    }
  };

  const handleCancelEdit = () => {
    setEditingCareer(null);
  };

  return (
    <Modal
      open={open}
      onOk={onClose}
      onCancel={onClose}
      width={800}
      styles={{
        content: {
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        },
        mask: {
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba( 240, 78, 35, 0.2)",
        },
      }}
    >
      <div style={{ padding: "10px" }}>
        <h3 style={{ padding: "10px" }}>Active Workers</h3>
        {workers.map((worker: any) => (
          <Card key={worker._id} style={{ marginBottom: 16 }}>
            <h4>{workerNameFormatter(worker.jobName)}</h4>
            <Divider />
            <p>
              {worker.environments.map((env: string) => (
                <Tag color="blue" key={env}>
                  {env}
                </Tag>
              ))}
            </p>
            <Divider />
            <Button
              style={worker.enabled ? { color: "green" } : { color: "red" }}
              onChange={() => toggleWorkerStatus(worker._id)}
              block
            >
              {worker.enabled ? " Ativo" : "Inativo"}
            </Button>
          </Card>
        ))}
      </div>

      <div>
        <h3 style={{ padding: "10px" }}>Niveis da carreira</h3>
        {careerLevels
          .sort((a, b) => a.start - b.start)
          .map((career) => (
            <Card
              title={career.position}
              extra={
                <Button onClick={() => handleEditCareer(career)}>Editar</Button>
              }
              key={career._id}
              style={{ marginBottom: 16 }}
            >
              {editingCareer === career._id ? (
                <Form form={form} layout="vertical">
                  <Form.Item label="Posição" name="position">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Começo" name="start">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Termino" name="max">
                    <Input />
                  </Form.Item>
                  <Form.Item label="VMF" name="maxUsage">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Premiação" name="product">
                    <Select>
                      {products?.map((product) => (
                        <Select.Option value={product?._id}>
                          {product?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Button onClick={() => handleSaveCareer(career._id)}>
                    Save
                  </Button>
                  <Button onClick={handleCancelEdit} style={{ marginLeft: 8 }}>
                    Cancel
                  </Button>
                </Form>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      padding: "10px",
                    }}
                  >
                    <Flag color="#000" size={25} />
                    <Statistic title="Começa" value={career.start} />
                  </div>
                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      padding: "10px",
                    }}
                  >
                    <FlagOff color="#000" size={25} />
                    <Statistic title="Termina" value={career.max} />
                  </div>

                  <Divider />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      padding: "10px",
                    }}
                  >
                    <Handshake color="#000" size={25} />
                    <Statistic title="VMF" value={career.maxUsage} />
                  </div>
                  <Divider />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      padding: "10px",
                    }}
                  >
                    <ShoppingBag color="#000" size={25} />
                    <label>Premiação</label>
                    <Products
                      key={currentProduct?._id}
                      name={currentProduct?.name}
                      value={currentProduct?.price}
                      auffs={currentProduct?.auff ?? 0}
                      directCommition={
                        currentProduct?.directCommissionValue ?? 0
                      }
                      image={currentProduct?.imageUrls[0]}
                      link={currentProduct?._id}
                    />
                  </div>
                  <Divider />
                </>
              )}
            </Card>
          ))}
      </div>
    </Modal>
  );
}
