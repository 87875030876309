import { Button, Flex, Spin } from "antd";
import { FC, useContext, useEffect, useState } from "react";
import { transactionSteps } from "../../../services/requests/steps";
import { ContextApi } from "../../../contexts";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseSquareOutlined,
  UserDeleteOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
  VideoCameraAddOutlined,
} from "@ant-design/icons";
import { Truck } from "lucide-react";

interface TransactionStepsInterface {
  transaction: any;
}

const TransactionSteps: FC<TransactionStepsInterface> = ({ transaction }) => {
  const [transactionsSteps, setTransactionsSteps] = useState<any>({
    steps: {},
  });
  const [loading, setLoading] = useState(true);

  const { products } = useContext(ContextApi);
  async function fetchStepsForTransactions(): Promise<any> {
    setLoading(true);
    setTransactionsSteps({
      steps: { notValid: false },
    });
    await transactionSteps(transaction?._id)
      .then((res) => {
        console.log(res?.data?.steps);
        setTransactionsSteps(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }

  useEffect(() => {
    fetchStepsForTransactions();
  }, [transaction]);

  const formatter = (step: string, process: boolean) => {
    if (transaction) {
      const product = products.find(
        (product) =>
          transaction &&
          transaction.items &&
          product._id === transaction.items[0]?.code
      );

      console.log(product, step, process);

      switch (step) {
        case "payment":
          return {
            label: "Pagamento",
            status: process ? "ok" : "não processado.",
            icon: process ? <CheckCircleOutlined /> : <CloseCircleOutlined />,
          };
        case "emailNotification":
          return {
            label: "Notificação por E-mail",
            status: process ? "ok" : "não enviada.",
            icon: process ? <CheckCircleOutlined /> : <CloseSquareOutlined />,
          };
        case "shipping":
          return {
            label: "Envio pelos Correios",
            status: process ? "ok" : "não enviado.",
            icon: process ? <Truck /> : <CloseSquareOutlined />,
          };
        case "activeEmailNotification":
          return product?.createUser
            ? {
                label: "E-mail de ativação de conta",
                status: process ? "ok" : "não enviado.",
                icon: process ? <UserDeleteOutlined /> : <UserAddOutlined />,
              }
            : {
                label: "Produto sem Ativação de Conta",
                status: "",
                icon: <UserSwitchOutlined />,
              };
        case "courseRelease":
          return product?.createUser
            ? {
                label: "Envio da Cademi",
                status: process ? "ok" : "não enviado.",
                icon: process ? (
                  <VideoCameraAddOutlined />
                ) : (
                  <CloseSquareOutlined />
                ),
              }
            : {
                label: "Produto sem Integração com a Cademi",
                status: "",
                icon: <CloseSquareOutlined />,
              };
        case "createdUser":
          return product?.createUser
            ? {
                label: "Criação de usuário",
                status: process ? "ok" : "não concluida.",
                icon: process ? (
                  <UsergroupAddOutlined />
                ) : (
                  <UsergroupDeleteOutlined />
                ),
              }
            : {
                label: "Produto sem Criação de Usuário",
                status: "",
                icon: <CloseSquareOutlined />,
              };
        default:
          return {
            label: "Sem processos",
            status: " cadastrados.",
            icon: <CloseSquareOutlined />,
          };
      }
    }
  };

  return (
    <Flex vertical gap="small" style={{ width: "100%" }}>
      {!loading ? (
        Object.keys(transactionsSteps?.steps).map((item: any) => {
          const formatted = formatter(item, transactionsSteps?.steps[item]);

          return formatted?.status ? (
            <Button
              key={item}
              block
              danger={formatted?.status !== "ok" ? true : false}
              size="large"
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {formatted?.label} {formatted?.status}
                <strong style={{ fontSize: "19px" }}>{formatted?.icon}</strong>
              </div>
            </Button>
          ) : (
            <></>
          );
        })
      ) : (
        <Spin />
      )}
    </Flex>
  );
};

export default TransactionSteps;
