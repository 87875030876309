export const filterOptions = [
  { id: "name", nome: "Nome" },
  { id: "active", nome: "Status" },
  { id: "graduation", nome: "Graduação" },
];

export const activeOptions = [
  { id: true, nome: "Ativo" },
  { id: false, nome: "Inativo" },
];
