import React from "react";
import { ColumnsType } from "antd/es/table";
import { Table } from "antd";
interface AddressProps {
  currentTransaction: any;
}

const Address: React.FC<AddressProps> = ({ currentTransaction }) => {
  const columns: ColumnsType<any> = [
    {
      title: "Nome",
      key: "customer.name",
      render: (data) => <span>{data?.customer?.name}</span>,
    },
    {
      title: "E-mail",
      key: "customer.email",
      render: (data) => <span>{data?.customer?.email}</span>,
    },
    {
      title: "CPF",
      key: "customer.document",
      render: (data) => <span>{data?.customer?.document}</span>,
    },
    {
      title: "Endereço",
      key: "customer.address",
      render: (data) => (
        <span>
          {" "}
          Logradouro: {data?.customer?.address?.line_1 || ""}{" "}
          {data?.customer?.address?.line_2 || ""}
        </span>
      ),
    },
    {
      title: "CEP",
      key: "customer.zip_code",
      render: (data) => <span>{data?.customer?.address?.zip_code}</span>,
    },
    {
      title: "Estado",
      key: "customer.state",
      render: (data) => <span>{data?.customer?.address?.state}</span>,
    },
    {
      title: "cidade",
      key: "customer.city",
      render: (data) => <span>{data?.customer?.address?.city}</span>,
    },
  ];

  return (
    <div>
      {currentTransaction && (
        <Table columns={columns} dataSource={[currentTransaction]} />
      )}
    </div>
  );
};

export default Address;
