// DashboardAdmin.tsx
import React, { useContext, useEffect, useState } from "react";
import styles from "./dashboard.module.scss";
import {
  IWithdrawal,
  getWithdrawal,
  processWithdrawal,
} from "../../services/requests/withdrawal";
import { toast } from "react-toastify";
import moment from "moment";
import BarChart from "../../components/Charts/Barchart";
import { getAllTransactions } from "../../services/requests/transactions";
import PieChart from "../../components/Charts/PieChart";
import LineChart from "../../components/Charts/LineChart";
import { getAllCommissions } from "../../services/requests/commissions";
import { Table, Space, Spin, Popconfirm } from "antd";
import { ColumnsType } from "antd/es/table";
import { CheckSquare, Receipt, XSquare, X, Check, Tag } from "lucide-react";
import { ContextApi } from "../../contexts";

const DashboardMaster: React.FC = () => {
  const { getAllUsers, users } = useContext(ContextApi);
  const [withdrawals, setWithdrawals] = useState<IWithdrawal[]>([]);
  const [commission, setCommission] = useState({
    comission: 0,
    balance: 0,
    withdraw: 0,
  });
  const [transactions, setTransactions] = useState({
    paid: 0,
    pending: 0,
    closed: 0,
    amountTime: [],
  });
  const [transactionsData, setTransactionsData] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    _fetch();
  }, []);

  const _fetch = async () => {
    getAllUsers();
    getWithdrawal()
      .then((response: any) => {
        if (!response.data) {
          setWithdrawals([]);
          return;
        }
        setWithdrawals(response.data);
      })
      .catch(() => toast.error("Não foi possível obter as retiradas"))
      .finally(() => setLoading(false));

    getAllTransactions()
      .then((response: any) => {
        setTransactionsData(response?.data);
        _filter(response.data);
      })
      .catch(() => toast.error("Não foi possível obter as transacoes"))
      .finally(() => setLoading(false));

    await getAllCommissions()
      .then((response: any) => {
        if (!response.data) {
          _filterCommissions([]);
          return;
        }

        _filterCommissions(response.data);
      })
      .finally(() => setLoading(false));
  };

  const _filter = (data: any) => {
    const transactions = data;

    const paid = transactions.filter(
      (transaction: any) => transaction.status === "paid"
    );
    const pending = transactions.filter(
      (transaction: any) => transaction.status === "pending"
    );

    const closed = transactions.filter(
      (transaction: any) => transaction.status === "canceled"
    );

    const transactionsByDay = transactions.reduce(
      (acc: any, transaction: any) => {
        const day = moment(transaction.created_at).format("YYYY-MM-DD");
        acc[day] = (acc[day] || 0) + 1;
        return acc;
      },
      {}
    );

    setTransactions({
      pending: pending.length,
      closed: closed.length,
      paid: paid.length,
      amountTime: transactionsByDay,
    });
  };

  const _process = async (id: string, payload: IWithdrawal, status: string) => {
    setLoading(true);
    payload.status = status;
    try {
      await processWithdrawal(id!, payload);
      _fetch();
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : "Não foi possível registrar a retirada"
      );
    } finally {
      setLoading(false);
    }
  };

  const _filterCommissions = (data: any) => {
    const totalMoney = data.balance.reduce(
      (sum: number, item: any) => sum + item.money,
      0
    );

    const commission = data.commissions.reduce(
      (sum: number, item: any) => sum + item.amount,
      0
    );
    const withdraws = data.withdraws.reduce((sum: number, item: any) => {
      if (item.status === "completed") {
        return sum + item.amount;
      }
      return sum;
    }, 0);

    setCommission({
      comission: commission,
      balance: totalMoney,
      withdraw: withdraws,
    });
  };

  const chartData = [
    transactions.paid,
    transactions.closed,
    transactions.pending,
  ];
  const chartLabels = ["Pagas", "Fechadas", "Processamento"];
  const pieChartLabels = ["Faturado", "Em contas", "Pago"];
  const columns: ColumnsType<any> = [
    {
      title: "Nome",
      key: "userId",
      render: (data) => <span>{data.userId.name}</span>,
    },
    {
      title: "Valor",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "E-mail",
      key: "userId.email",
      render: (data) => <span>{data.userId.email}</span>,
    },
    {
      title: "Tipo",
      dataIndex: "method",
      key: "method",
    },
    {
      title: "Chave Pix",
      dataIndex: "pixKey",
      key: "pixKey",
    },
    {
      title: "NIT",
      key: "userId.nit",
      render: (data) => <span>{data.userId.nit ? data.userId.nit : "-"}</span>,
    },
    {
      title: "Nota Fiscal",
      key: "nf",
      render: (data) => (
        <Space size="middle">
          <Receipt
            onClick={() =>
              window.open(data.invoice, "_blank", "noopener,noreferrer")
            }
            className={styles.icon}
          />
        </Space>
      ),
    },
    {
      title: "Ações",
      key: "action",
      render: (data) => (
        <Space size="large">
          <Popconfirm
            title="Aprovar a transferência"
            onConfirm={() => _process(data._id, data, "completed")}
            okText={<Check />}
            cancelText={<X />}
          >
            <CheckSquare className={styles.icon} />
          </Popconfirm>

          <Popconfirm
            title="Recusar a transferência"
            onConfirm={() => _process(data._id, data, "cancelled")}
            okText={<Check />}
            cancelText={<X />}
          >
            <XSquare className={styles.icon} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const columnsTransactions: ColumnsType<any> = [
    {
      title: "Comprador",
      key: "custumer.name",
      render: (data) => <span>{data?.customer?.name}</span>,
    },
    {
      title: "E-mail",
      key: "custumer.email",
      render: (data) => <span>{data?.customer?.email}</span>,
    },

    {
      title: "Vendedor",
      key: "sellerId",
      render: (data) => {
        const seller = users.find((user) => user._id === data.sellerId);

        return <span>{seller ? seller.name : "Vendedor não encontrado"}</span>;
      },
    },
    {
      title: "Valor",
      key: "amount",
      render: (data) => <span>R$ {Number(data?.amount / 100).toFixed(2)}</span>,
    },
    {
      title: "Cidade",
      key: "custumer.address.city",
      render: (data) => <span>{data?.customer?.address?.city}</span>,
    },
    {
      title: "Estado",
      key: "custumer.address.state",
      render: (data) => <span>{data?.customer?.address?.state}</span>,
    },
    {
      title: "Rua",
      key: "custumer.address.line_1",
      render: (data) => (
        <span>
          {data?.customer?.address?.line_1 +
            ", " +
            data?.customer?.address?.line_2}
        </span>
      ),
    },
    {
      title: "CEP",
      key: "custumer.address.city",
      render: (data) => <span>{data?.customer?.address?.zip_code}</span>,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "Opcao",
      key: "options",
      render: (data) => <span>{data?.options?.value}</span>,
    },
    {
      title: "Data",
      key: "createdAt",
      render: (data) => <span>{moment(data.createdAt).format("LLL")}</span>,
    },
  ];

  return (
    <section className={styles.dashBoardPage}>
      {!loading && (
        <div className={styles.container}>
          <p>
            <h1>Solicitações de saque:</h1>
          </p>
          <Table
            pagination={{ pageSize: 5 }}
            columns={columns}
            dataSource={withdrawals as any}
          />
          <h1 style={{ marginTop: "3%" }}>Graficos:</h1>
          <div className={styles.charts}>
            <div className={styles.barChartContainer}>
              <BarChart
                data={chartData}
                labels={chartLabels}
                label="Transacoes"
              />
            </div>
            <div className={styles.pieChartContainer}>
              <PieChart
                data={[
                  commission.balance,
                  commission.comission,
                  commission.withdraw,
                ]}
                labels={pieChartLabels}
                label="Balanco"
              />
            </div>
            <div className={styles.lineChartContainer}>
              <LineChart
                transactions={Object.entries(transactions.amountTime)}
              />
            </div>
          </div>
          <h1>Vendas:</h1>
          <Table
            pagination={{ pageSize: 5 }}
            columns={columnsTransactions}
            dataSource={transactionsData as any}
          />
        </div>
      )}

      <Spin spinning={loading} fullscreen />
    </section>
  );
};

export default DashboardMaster;
