import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, LinearScale, CategoryScale } from "chart.js/auto";

Chart.register(LinearScale, CategoryScale);

interface BarChartProps {
  data: number[];
  labels: string[];
  label: string;
}

const BarChart: React.FC<BarChartProps> = ({ data, labels, label }) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: data,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={chartData} options={chartOptions} />;
};

export default BarChart;
