import {
  Eye,
  FilePlus2,
  HelpCircleIcon,
  PenSquare,
  Plus,
  Trash,
} from "lucide-react";
import { InputNumber, Modal, Space, Tooltip, Spin } from "antd";
import { ChangeEvent, useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";

import { IBankAccount } from "../../contexts/interfaces";
import { SelectSearch } from "../../components/inputs/searchSelectInput/selectSearch";
import {
  processWithdrawal,
  withdrawal,
} from "../../services/requests/withdrawal";
import { toast } from "react-toastify";
import { getWithdrawal } from "../../services/requests/withdrawal";
import { uploadInvoice } from "../../services/requests/files";
import { InputSimpleSelect } from "../../components/inputs/simpleSelect/simpleSelectInput";
import { InputTextSimple } from "../../components/inputs/simpleText/inputSimpleText";
import { ContextApi } from "../../contexts";
import Filters from "../../libs/Filters";
import styles from "./financial.module.scss";
import React from "react";
import { Steps } from "antd";
import {
  FileAddOutlined,
  LoadingOutlined,
  SolutionOutlined,
} from "@ant-design/icons";

import "moment/locale/pt-br";

type ReverseTypesKey = {
  [key: string]: number;
};

export const Financial = () => {
  const typesKey = {
    "1": "CPF",
    "2": "EMAIL",
    "3": "CELULAR",
    "4": "ALEATORIA",
    "5": "CNPJ",
  };

  const reverseTypesKey: ReverseTypesKey = {
    CPF: 1,
    EMAIL: 2,
    CELULAR: 3,
    ALEATORIA: 4,
    CNPJ: 5,
  };

  moment.locale("pt-br");
  const {
    user,
    transactions,
    getAllTransactionsByUserId,
    products,
    getAllProducts,
    commissions,
    getAllCommissionsByUserId,
    getBanks,
    banks,
    profileEditAgent,
  } = useContext(ContextApi);

  useEffect(() => {
    getAllProducts();
    getBanks();
  }, []);

  const [openEditBankDetails, setOpenEditBankDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  const [seeProfits, setSeeProfits] = useState(false);
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [fileList, setFileList] = useState<any>({});
  const [seeInvestment, setSeeInvestment] = useState(false);
  const [bankAccount, setBankAccount] = useState<IBankAccount>({
    name: "",
    bank: "",
    number: 0,
    ispb: "",
    cpf: "",
    ag: 0,
    cc: 0,
    dv: "",
    pix: [
      {
        key: "",
        type: "",
      },
    ],
  });
  const [showTooltipBank, setShowTooltipBank] = useState(false);
  const [numberKeys, setNumberKeys] = useState(1);
  const [withdrawals, setWithdrawals] = useState({
    msg: "Transferir",
    disabled: true,
    data: [],
  });

  const changeBankAccount = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (e.target.id === "cpf") {
      setBankAccount({
        ...bankAccount,
        [e.target.id]: Filters.clearStringOnlyNumbers(e.target.value),
      });
      return;
    }
    setBankAccount({
      ...bankAccount,
      [e.target.id]: e.target.value,
    });
  };

  const _fetch = () => {
    setLoading(true);
    if (user) {
      getAllTransactionsByUserId(user._id);
      getAllCommissionsByUserId(user._id);
    }
    if (user?.bankAccount) {
      setBankAccount(user.bankAccount);
    }

    getWithdrawal()
      .then((response: any) => {
        if (!response.data) {
          setWithdrawals({
            msg: "Transferir",
            disabled: false,
            data: [],
          });
          return;
        }

        const solicitacoes = response.data.find(
          (item: any) => item?.userId?._id === user?._id
        );

        setWithdrawals(
          solicitacoes
            ? {
                msg: "Você possui solicitações em andamento!",
                disabled: true,
                data: response.data,
              }
            : {
                msg: "Transferir",
                disabled: false,
                data: [],
              }
        );
      })
      .catch(() => toast.error("Não foi possivel obter as retiradas"))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    _fetch();
  }, []);

  const changeBanckSelect = (value: string) => {
    const bank = banks?.find((bank) => bank.ispb === value);
    if (bank) {
      setBankAccount({
        ...bankAccount,
        bank: bank.name,
        ispb: bank.ispb,
        number: bank.code,
      });
    }
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!bankAccount.bank) {
      return setShowTooltipBank(true);
    }

    if (user) {
      let { password, ...rest } = user;
      await profileEditAgent(user._id as string, {
        ...rest,
        bankAccount: bankAccount,
      });
    }

    setOpenEditBankDetails(false);
  };

  const getStatus = (status: string) => {
    if (status === "pending") {
      return { label: "Processando", style: styles.processing };
    }
    if (status === "canceled") {
      return { label: "Cancelado", style: styles.canceled };
    }
    if (status === "paid") {
      return { label: "Concluído", style: styles.concluded };
    }
    if (status === "refunded") {
      return { label: "Estornado", style: styles.refunded };
    }
    return { label: "", style: styles.processing };
  };

  const countKeys = useMemo(() => {
    return bankAccount?.pix?.length || 0;
  }, [bankAccount]);

  const addKey = () => {
    if (countKeys < 5) {
      setNumberKeys((numberKeys) => numberKeys + 1);
      setBankAccount({
        ...bankAccount,
        pix: [...(bankAccount?.pix || []), { key: "", type: "" }],
      });
    }
  };

  const changeKey = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const key = e.target.id.split("-")[1];
    const keyType = e.target.id.split("-")[0];
    let value = e.target.value;

    const newPix = bankAccount?.pix?.map((pix, index) => {
      if (index === Number(key)) {
        return {
          ...pix,
          [keyType]: value,
        };
      }
      return pix;
    });

    const removeMask = newPix?.map((pix, index) => {
      let withoutMask = pix;
      if (pix.type === "1" || pix.type === "5" || pix.type === "3") {
        withoutMask = {
          type: typesKey[pix.type],
          key: pix.key.replace(/\D/g, ""),
        };
      }
      return withoutMask;
    });

    setBankAccount({
      ...bankAccount,
      pix: removeMask,
    });
  };

  const maskPix = (type: string, pix: string) => {
    switch (type) {
      case "CPF":
      case "CNPJ":
        return Filters.inputMaskCPFCNPJ(pix);
      case "CELULAR":
        return Filters.inputMaskTELWithDDD(pix);
      default:
        return pix;
    }
  };

  const requestWithdrawal = async () => {
    setLoading(true);

    if (withdrawals.disabled) {
      toast.warning(
        "Aguarde suas solicitações serem processadas para solicitar uma nova."
      );
      setLoading(false);
      return;
    }

    if (!amount) {
      toast.warning("O valor deve ser informado");
      setLoading(false);
      return;
    }

    if (amount <= 0) {
      toast.warning("O valor deve ser maior que zero");
      setLoading(false);
      return;
    }

    if (!fileList.type) {
      toast.warning("Uma nota fiscal deve ser fornecida");
      setLoading(false);
      return;
    }

    const payload = {
      method: "pix",
      amount: amount || 0,
      status: "pending",
    };
    let id = "";
    await withdrawal(payload)
      .then(async (response: any) => {
        id = response?.data?._id;
        await uploadInvoice(response?.data?._id, fileList)
          .then(() => {
            toast.success("Retirada solicidata com sucesso");
          })
          .catch(async () => {
            payload.status = "cancelled";
            await processWithdrawal(id, payload);
            toast.error("Erro ao fazer o upload da NF");
          });
      })
      .catch(() => {
        toast.error("Nao foi possivel solicitar sua retirada");
      })
      .finally(() => {
        _fetch();
      });
  };

  async function handleSendDoc(arquivo: any) {
    if (arquivo.type !== "application/pdf") {
      toast.error("O arquvivo deve ser to tipo PDF");
      return;
    }
    setFileList(arquivo);
  }

  const handleHrefClick = () => {
    window.location.href =
      "https://www.youtube.com/watch?v=0JVqmJFAhAg&ab_channel=SebraePR";
  };

  return (
    <section className={styles.financialPage}>
      <div className={styles.financialBankData}>
        <h1>Meus Dados Bancários</h1>
        <button
          className={styles.financialBankDataButton}
          onClick={() => setOpenEditBankDetails(true)}
        >
          <PenSquare /> <span> Meus Dados Bancários</span>{" "}
        </button>

        <div className={styles.financialBankDataUser}>
          <div className={styles.financialBankDataUserItem}>
            <h3>Razão Social</h3>
            <span>{user?.bankAccount?.name}</span>
          </div>
          <div className={styles.financialBankDataBorder} />
          <div className={styles.financialBankDataUserItem}>
            <h3>CNPJ</h3>
            <span>{user?.bankAccount?.cpf}</span>
          </div>
          <div className={styles.financialBankDataBorder} />

          <div className={styles.financialBankDataUserItem}>
            <h3>Banco</h3>
            <span>{user?.bankAccount?.bank}</span>
          </div>
        </div>

        <div className={styles.financialBankDataAccountUser}>
          <div className={styles.financialBankDataUserItem}>
            <h3>Agência</h3>
            <span>{user?.bankAccount?.ag}</span>
          </div>
          <div className={styles.financialBankDataBorder} />

          <div className={styles.financialBankDataUserItem}>
            <h3>Conta</h3>
            <span>{user?.bankAccount?.cc}</span>
          </div>
          <div className={styles.financialBankDataBorder} />

          <div className={styles.financialBankDataUserItem}>
            <h3>Chaves Pix</h3>
            {user?.bankAccount?.pix?.map((pix, index) => {
              return (
                <span key={index}>
                  {pix.key} <br />
                </span>
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.financialAccountAvailable}>
        <div className={styles.financialAccountAvailableItem}>
          <h2>Disponível para transferência</h2>
          <span>
            {Filters.convertMoneyTextMask(commissions?.balance?.money)}
          </span>
          <strong>Historico de transacoes</strong>
        </div>
        {!withdrawals.disabled ? (
          <>
            <div className={styles.withdrawal}>
              <div>
                <strong>Valor para transferir:</strong>
                <InputNumber
                  type="number"
                  placeholder="R$: 20"
                  value={amount}
                  onChange={(value: number | null) => {
                    if (value !== null) {
                      setAmount(Math.floor(value));
                    }
                  }}
                  min={0}
                  style={{ minWidth: "120px" }}
                  disabled={withdrawals.disabled}
                />
              </div>
              <div className={styles.vertical} />
              <input
                id="contained-button-file-workshop"
                type="file"
                style={{ display: "none" }}
                onChange={(event) => {
                  if (event.target.files) {
                    handleSendDoc(event.target.files[0]);
                  }
                }}
              />
              {!fileList?.type ? (
                <>
                  <label
                    htmlFor="contained-button-file-workshop"
                    style={{ cursor: "pointer" }}
                  >
                    <Space size="large" className={styles.information}>
                      <FilePlus2 className={styles.icon} size={30} />
                      <p style={{ whiteSpace: "nowrap" }}>Anexar Nota Fiscal</p>
                    </Space>
                  </label>

                  <Tooltip
                    style={{
                      width: "300vw",
                      height: "300vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    title={() => (
                      <span>
                        Em caso de dúvida para gerar a NF, clique{" "}
                        <i
                          style={{ cursor: "pointer" }}
                          onClick={() => handleHrefClick()}
                        >
                          aqui
                        </i>
                      </span>
                    )}
                  >
                    <HelpCircleIcon
                      size={18}
                      style={{
                        color: "red",
                      }}
                    />
                  </Tooltip>
                </>
              ) : (
                <p>
                  {fileList?.name.slice(0, 20)} ...{" "}
                  <Trash
                    size={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => setFileList({})}
                  />
                </p>
              )}
            </div>
            <div className={styles.textDateTransfer}>
              <button type="button" onClick={requestWithdrawal}>
                {withdrawals.msg}
              </button>

              <p>
                Você pode solicitar a transferência a qualquer momento, porém o
                pagamento sempre é efetivado entre os dias 17 e 22 de cada mês.
              </p>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                paddingBottom: "50px",
              }}
            >
              Sua solicitação foi recebida e está em processo de análise!
            </span>
            <Steps
              style={{
                paddingBottom: "50px",
              }}
              items={[
                {
                  title: "Solicitação",
                  status: "finish",
                  icon: <FileAddOutlined />,
                },
                {
                  title: "Processamento",
                  status: "process",
                  icon: <LoadingOutlined />,
                },
                {
                  title: "Finalização",
                  status: "wait",
                  icon: <SolutionOutlined />,
                },
              ]}
            />
          </div>
        )}
      </div>

      <div
        className={styles.financialInvestments}
        onClick={() => setSeeInvestment((investiment) => !investiment)}
      >
        <h3>Investimentos</h3>
        {seeInvestment ? (
          <ul onClick={() => setSeeInvestment(false)}>
            {transactions?.map((transaction) => {
              const status = getStatus(transaction?.status);
              const date = moment(transaction?.updatedAt).format("LLL");
              const amount = transaction?.amount / 100;
              const name = transaction?.items
                .map(
                  (item: any) =>
                    products.find((product) => product._id === item.code)?.name
                )
                .join(",");
              return (
                <li>
                  <div className={styles.values}>
                    <h6>{Filters.convertMoneyTextMask(amount)}</h6>
                    <p>{name}</p>
                  </div>

                  <div className={styles.information}>
                    <span className={status.style}>{status.label}</span>
                    <p>{date}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className={styles.financialSeeInvestment}>
            <Eye color="#D2D2D2" size={34} />
            <span>Mostrar Investimentos</span>
          </div>
        )}
      </div>
      <div
        className={styles.financialProfits}
        onClick={() => setSeeProfits((profits) => !profits)}
      >
        <h3>Lucros</h3>

        {seeProfits ? (
          <ul onClick={() => setSeeProfits(false)}>
            {commissions?.commissions.map((commission: any) => {
              const status = getStatus(commission?.status);
              const date = moment(
                commission?.updatedAt ?? commission?.createdAt
              ).format("LLL");

              const totalAmount =
                commission.amount > 0 ? commission.amount : commission.auff;
              const amount = Filters.convertMoneyTextMask(totalAmount);

              const name = commission?.description;

              return (
                <li>
                  <div className={styles.values}>
                    <h6>{` ${
                      commission.amount > 0 ? amount : amount.split(",")[0]
                    }`}</h6>
                    <p>{name}</p>
                  </div>

                  <div className={styles.information}>
                    <span className={status.style}>{status.label}</span>
                    <p>{date}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          <div className={styles.financialSeeProfits}>
            <Eye color="#D2D2D2" size={34} />
            <span>Mostrar Lucros</span>
          </div>
        )}
      </div>

      <button hidden />

      <Modal
        open={openEditBankDetails}
        onCancel={() => {
          setOpenEditBankDetails(false);
          setShowTooltipBank(false);
        }}
        width="30rem"
        centered
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        afterClose={() => setShowTooltipBank(false)}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
      >
        <div className={styles.financialModalEdit}>
          <h3>Alterar Dados Bancários</h3>

          <form onSubmit={handleFormSubmit}>
            <InputTextSimple
              name="name"
              placeholder="Razão Social"
              onChange={changeBankAccount}
              required
              value={bankAccount?.name || ""}
            />
            <InputTextSimple
              name="cpf"
              placeholder="CNPJ"
              onChange={changeBankAccount}
              required
              value={Filters.inputMaskCNPJ(bankAccount?.cpf || "")}
            />
            <SelectSearch
              options={banks
                ?.filter((valid) => valid.code && valid.name !== undefined)
                ?.map((bank) => ({
                  label: bank.code + " - " + bank.name,
                  value: bank.ispb,
                }))}
              onChangeSelect={changeBanckSelect}
              value={bankAccount?.ispb}
            />

            <div className={styles.financialModalEditAccount}>
              <InputTextSimple
                name="ag"
                placeholder="Agência"
                onChange={changeBankAccount}
                required
                value={bankAccount?.ag || ""}
                maxLength={4}
              />
              <InputTextSimple
                name="cc"
                placeholder="Conta corrente"
                onChange={changeBankAccount}
                required
                value={bankAccount?.cc || ""}
              />
              <InputTextSimple
                name="dv"
                placeholder="Digito verificador"
                onChange={changeBankAccount}
                required
                value={bankAccount?.dv || ""}
                maxLength={2}
              />
            </div>

            <div className={styles.financialModalEditKey}>
              <h4>Chaves Pix</h4>
              {bankAccount?.pix?.map((pix, index) => {
                return (
                  <>
                    <InputSimpleSelect
                      name="KeyType"
                      id={`type-${index}`}
                      data={[
                        {
                          id: 1,
                          nome: "CPF",
                        },
                        {
                          id: 2,
                          nome: "EMAIL",
                        },
                        {
                          id: 3,
                          nome: "CELULAR",
                        },
                        {
                          id: 4,
                          nome: "ALEATORIA",
                        },
                        {
                          id: 5,
                          nome: "CNPJ",
                        },
                      ]}
                      onChange={changeKey}
                      optionZero="Selecionar o tipo da chave"
                      value={reverseTypesKey[pix?.type]}
                    />
                    <div className={styles.financialModalAddKeys}>
                      <InputTextSimple
                        name={`key-${index}`}
                        placeholder="Chave Pix"
                        onChange={changeKey}
                        value={maskPix(pix.type, pix.key)}
                      />
                      <button type="button" onClick={addKey}>
                        <Plus />
                      </button>
                      {index > 0 && (
                        <button
                          type="button"
                          onClick={() => {
                            const newPix = bankAccount?.pix?.filter(
                              (pix, i) => i !== index
                            );
                            setBankAccount({
                              ...bankAccount,
                              pix: newPix,
                            });
                            setNumberKeys((numberKeys) => numberKeys - 1);
                          }}
                        >
                          <Trash />
                        </button>
                      )}
                    </div>
                  </>
                );
              })}

              <button type="submit">Salvar Alterações</button>
            </div>
          </form>
        </div>
      </Modal>
      <Spin spinning={loading} fullscreen />
    </section>
  );
};
