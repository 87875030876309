import { createSlice } from "@reduxjs/toolkit";

export const transactionsRedux = createSlice({
  name: "transactions",
  initialState: {
    value: [],
    not: [],
  },
  reducers: {
    resetTransaction: (state) => {
      state.value = [];
    },

    setTransaction: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { resetTransaction, setTransaction } = transactionsRedux.actions;

export default transactionsRedux.reducer;
