import React, { useEffect, useState, useContext } from "react";
import { getUserById } from "../../../services/requests/users";
import { Avatar, Card, Spin } from "antd";
import { ContextApi } from "../../../contexts";

interface SellerProps {
  currentTransaction: any;
}

const Seller: React.FC<SellerProps> = ({ currentTransaction }) => {
  const { getFiles, files } = useContext(ContextApi);

  const [currentSeller, setCurrentSeller] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const findcurrentSeller = async () => {
    setLoading(true);
    if (currentTransaction.sellerId) {
      try {
        const { data } = await getUserById(currentTransaction.sellerId);
        setCurrentSeller(data.user);
      } catch (error) {
        setCurrentSeller({ name: "", email: "", telefone: "" });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (currentSeller) {
      getFiles("avatar", currentSeller?._id ?? "");
      console.log(currentSeller, files);
    }
  }, [currentSeller]);

  const avatar = files.find((f) => f.fieldId === currentSeller?._id) ?? null;

  const avatarDefault = require("../../../assets/images/user.png");

  useEffect(() => {
    findcurrentSeller();
  }, [currentTransaction]);

  return (
    <Card
      size="small"
      style={{
        width: "350px",
        display: "flex",
        alignItems: "center",
        padding: "16px",
        borderRadius: "8px",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
      }}
    >
      {!loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            alt="perfil"
            style={{
              objectFit: "contain",
              height: "5rem",
              width: "5rem",
              borderRadius: "5rem",
              marginRight: "16px",
            }}
            src={avatar?.path ?? avatarDefault}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: 700,
                fontSize: "1rem",
                margin: 0,
              }}
            >
              {currentSeller?.name || ""}
            </p>
            <p
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: 400,
                fontSize: "0.85rem",
                color: "#888",
                margin: 0,
              }}
            >
              E-mail: {currentSeller?.email || ""}
            </p>
            <p
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: 400,
                fontSize: "0.85rem",
                color: "#888",
                margin: 0,
              }}
            >
              Telefone: {currentSeller?.phone || ""}
            </p>
            <p
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: 400,
                fontSize: "0.85rem",
                color: "#888",
                margin: 0,
              }}
            >
              CPF: {currentSeller?.cpf || ""}
            </p>
          </div>
        </div>
      )}
      <Spin spinning={loading} />
    </Card>
  );
};

export default Seller;
