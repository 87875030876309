import React from "react";

import styles from "./styleInputText.module.scss";

interface InputTextSimpleProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  required?: boolean;
  error?: boolean;
}

export function InputTextSimple(props: InputTextSimpleProps): JSX.Element {
  const { name, required, error, ...rest } = props;
  return (
    <>
      <input
        className={!error ? styles.container : styles.containerError}
        type="text"
        id={name}
        required={required}
        {...rest}
      />
    </>
  );
}
