import { Modal } from "antd";
import { FileDown } from "lucide-react";

import { LineUserReport } from "./lineUserReport";
import { CustomButton } from "../../buttons/customButton";
import { ColumnsType } from "antd/es/table";
import { Table } from "antd";

import styles from "./styleModal.module.scss";

interface ModalReportsProps {
  changeModal: () => void;
  showModal: boolean;
  reports?: any[];
  depth?: number;
}

const columns: ColumnsType<any> = [
  {
    title: "ID",
    key: "_id",
    dataIndex: "_id",
  },
  {
    title: "Nome",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "E-mail",
    key: "email",
    dataIndex: "email",
  },
  {
    title: "Cidade",
    dataIndex: "city",
    key: "city",
  },
  {
    title: "Estado",
    dataIndex: "state",
    key: "state",
  },
  {
    title: "Telefone",
    key: "phone",
    dataIndex: "phone",
  },
];

export function ModalReports({
  changeModal,
  showModal,
  reports,
  depth,
}: ModalReportsProps): JSX.Element {
  return (
    <Modal
      open={showModal}
      onOk={() => {}}
      onCancel={() => {
        changeModal();
      }}
      okButtonProps={{
        style: {
          display: "none",
        },
      }}
      cancelButtonProps={{
        style: {
          display: "none",
        },
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      width={100}
    >
      <div className={styles.header}>
        <h1>Relatório de Parceiros 1ª Esfera</h1>
      </div>
      <div className={styles.table}>
        <Table
          pagination={{ pageSize: 25 }}
          columns={columns}
          dataSource={reports as any}
        />
        <div className={styles.boxButton}>
          <CustomButton
            style={{
              width: "100%",
              height: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              changeModal();
            }}
          >
            Voltar Para o Dashboard
          </CustomButton>
          <div className={styles.contentButtonDownload}>
            <p>Quer guardar esse relatório em seu dispositivo?</p>
            <span>
              Baixe o PDF clicando aqui
              <FileDown size={13} />
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
}
