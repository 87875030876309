import React, { useContext, useEffect, useState } from "react";

import styles from "./styleProducts.module.scss";
import { Modal, Space, Table, Tooltip } from "antd";
import { ContextApi } from "../../../contexts";
import { ColumnsType } from "antd/es/table";
import Filters from "../../../libs/Filters";
import { Edit, Plus, Trash, Ban, CheckCircle } from "lucide-react";
import { CustomButton } from "../../../components/buttons/customButton";
import { useNavigate } from "react-router-dom";
import ModalAddProducts from "./product-handler/add-product-modal";
import ModalUpdateProducts from "./product-handler/update-product-modal";

interface DataType {
  name: string;
  description: string;
  price: number;
}

const Products: React.FC = () => {
  const { getAllProducts, products, deleteProductRequest, dimensions } =
    useContext(ContextApi);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState("");
  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Preço",
      dataIndex: "price",
      key: "price",
      render: (price) => <div>{Filters.convertMoneyTextMask(price)}</div>,
    },
    {
      title: "Recorrente",
      dataIndex: "recorrentPayment",
      key: "recorrentPayment",
      render: (recorrent) =>
        !recorrent ? (
          <Ban style={{ color: "red" }} />
        ) : (
          <CheckCircle style={{ color: "green" }} />
        ),
    },
    {
      title: "Ações",
      key: "action",
      render: (data) => {
        return (
          <Space size="middle">
            <Edit
              onClick={() => {
                setCurrentProduct(data._id);
                setOpenEditModal(true);
              }}
              className={styles.icon}
            />
            <Tooltip
              placement="top"
              title={
                <div className={styles.tooltip}>
                  Realmente deseja excluir este produto?
                  <CustomButton onClick={() => deleteProductRequest(data._id)}>
                    Sim
                  </CustomButton>
                </div>
              }
            >
              <Trash className={styles.icon} />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  if (dimensions.width > 768) {
    columns.splice(1, 0, {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    });
  }
  function handleOpenCloseRegisterModal() {
    setOpenModal(!openModal);
  }
  function handleOpenCloseUpdateModal() {
    setOpenEditModal(!openEditModal);
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Produtos</div>
        <CustomButton onClick={() => setOpenModal(!openModal)}>
          <div className={styles.button}>
            Adicionar <Plus />
          </div>
        </CustomButton>
      </div>
      <Table
        pagination={{ pageSize: 5 }}
        columns={columns}
        dataSource={products}
      />
      <Modal
        open={openEditModal}
        title="Atualização de produtos"
        onOk={() => {}}
        onCancel={() => {
          handleOpenCloseUpdateModal();
        }}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        width={650}
        styles={{
          mask: {
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba( 240, 78, 35, 0.2)",
          },
        }}
      >
        <ModalUpdateProducts
          close={handleOpenCloseUpdateModal}
          productId={currentProduct}
        />
      </Modal>

      <Modal
        open={openModal}
        title="Cadastro de produtos"
        onOk={() => {}}
        onCancel={() => {
          handleOpenCloseRegisterModal();
        }}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        width={650}
        styles={{
          mask: {
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba( 240, 78, 35, 0.2)",
          },
        }}
      >
        <ModalAddProducts close={handleOpenCloseRegisterModal} />
      </Modal>
    </div>
  );
};

export default Products;
