import { useContext, useEffect } from "react";
import styles from "./career.module.scss";
import { ContextApi } from "../../contexts";
import { FrontText } from "./components/FrontText";
import { AuffText } from "./components/AuffText";
import { Result } from "antd";

const Career = () => {
  const { user, getAllCareer, career } = useContext(ContextApi);

  useEffect(() => {
    getAllCareer();
  }, [getAllCareer]);

  return (
    <>
      {!career && (
        <section className={styles.careerPage}>
          <div className={styles.notFound}>
            <Result
              status="404"
              title="Estamos processando os dados da sua carreira!"
              subTitle="Aguarde, os dados de progressão da carreira irão aparecer de forma automática."
            />
          </div>
        </section>
      )}
      {career && (
        <section className={styles.careerPage}>
          <div className={styles.careerPageAgentData}>
            <div className={styles.careerPageAgentTitle}>
              <h2>{user?.name}</h2>
              <span>#{user?.userID}</span>
            </div>

            <div className={styles.careerPageAgentfront}>
              {career.fronts.slice(0, 4).map((userCarrer, index) => {
                const details = userCarrer?.user?.name
                  ? userCarrer?.user?.name
                  : `Frente ${userCarrer + 1}`;
                return (
                  <FrontText
                    key={index}
                    title={`${details}`}
                    AA={userCarrer.AA}
                    AG={userCarrer.AG}
                    borderRight={true}
                  />
                );
              })}

              {career.fronts.length > 4 && (
                <FrontText
                  title="Outras frentes"
                  AA={career.fronts
                    .slice(4)
                    .reduce((total, front) => total + front.AA, 0)}
                  AG={career.fronts
                    .slice(4)
                    .reduce((total, front) => total + front.AG, 0)}
                />
              )}
            </div>

            <div className={styles.careerPageAgentInformation}>
              <AuffText
                title="AG"
                text="AUFFS Gerados"
                auff={career.generatedAuffs}
              />
              <div className={styles.careerPageAgentfrontBorder} />
              <AuffText
                title="AA"
                text="AUFFS Aproveitados"
                auff={career.utilizedAuffs}
              />
            </div>
          </div>
          <div className={styles.careerPageCommonAgent}>
            <div className={styles.careerPageCommonAgentText}>
              <h4>Nivel:</h4>
              <h5>{career.careerLevel}</h5>
              <span>{career.careerPoints} Pontos Atuais</span>
            </div>
            <p style={{ marginRight: "0.5%", whiteSpace: "nowrap" }}>
              <strong style={{ marginRight: "0.5%" }}>Próximo Nível: </strong>{" "}
              {career.careerNextLevel}
              <span style={{ whiteSpace: "nowrap" }}>
                {" "}
                {career.careerNextLevelPoints} pontos
              </span>
            </p>
          </div>
          <div className={styles.careerPageAufssPersonal}>
            <h4>
              AUFFS Pessoais <span>{career.personalAuffs}</span>
            </h4>
          </div>
          <div className={styles.careerPageAufssTotal}>
            <h4>
              Total de AUFFS <span>{career.totalAuffs}</span>
            </h4>
          </div>
        </section>
      )}
    </>
  );
};

export default Career;
