import { useState, useEffect, useContext } from "react";

import { HeaderReports } from "../../components/structure/headerReports/headerReports";
import { ModalReports } from "../../components/structure/modalListReports/modalReports";
import { GridButtom } from "../../components/structure/gridBottom/gridBottom";
import { ContextApi } from "../../contexts";
import { useExtractChildren } from "../../hooks/useExtractChildren";
import { Spin } from "antd";

export function StructurePage(): JSX.Element {
  const { getSpheresByUser, spheresResp, user } = useContext(ContextApi);
  const [showModal, setShowModal] = useState(false);
  const [depth, setDepth] = useState(0);
  const [loading, setLoading] = useState(true);

  const onChangeModal = (depth?: number) => {
    setDepth(depth || 0);
    setShowModal(!showModal);
  };

  useEffect(() => {
    const userId: string | any = user?._id;
    getSpheresByUser(userId);

    setTimeout(() => setLoading(false), 2000);
  }, []);

  const spheres = useExtractChildren(spheresResp?.rootNode);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <HeaderReports onChangeModal={onChangeModal} reports={spheres} />
      <ModalReports
        changeModal={onChangeModal}
        showModal={showModal}
        reports={spheres}
        depth={depth}
      />
      {spheres && (
        <GridButtom othersReports={spheresResp?.totalSellsByProduct} />
      )}
      <Spin spinning={loading} fullscreen />
    </div>
  );
}
