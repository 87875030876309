import React, { useCallback, useContext, useEffect, useState } from "react";

import styles from "./styleDocuments.module.scss";
import { ContextApi } from "../../../contexts";
import { CustomButton } from "../../../components/buttons/customButton";
import { ArrowLeft, CheckCheck, Trash, UploadIcon } from "lucide-react";
import { InputTextSimple } from "../../../components/inputs/simpleText/inputSimpleText";
import { Result, Upload, UploadFile, UploadProps } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getUniqueDocumentById,
  updateDocument,
} from "../../../services/requests/files";
import queryString from "query-string";

const AddDocuments: React.FC = () => {
  const { documentId } = useParams();
  const { search } = useLocation();
  const { id } = queryString.parse(search);
  const navigate = useNavigate();
  const {
    documentById,
    documentFiltered: initialData,
    clearDocumentFiltered,
    addDocumentRequest,
    user,
  } = useContext(ContextApi);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileDoc, setFileDoc] = useState<any>();

  useEffect(() => () => clearDocumentFiltered(), [clearDocumentFiltered]);

  useEffect(() => {
    if (documentId) {
      documentById(documentId);
    }
  }, [documentId, documentById]);

  const fetch = async () => {
    return await getUniqueDocumentById(id as any).then((response: any) => {
      setFileDoc(response?.data);
      if (response?.data?.response?.name) {
        setName(response.data.response.name);
        setDescription(response.data.response.description);
      }
    });
  };

  useEffect(() => {
    if (id) {
      fetch();
    }
  }, []);

  const handleChange: UploadProps["onChange"] = (info) => {
    let newFileList = [...info.fileList];

    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = URL.createObjectURL(file.response.file);
      }
      return file;
    });

    setFileList(newFileList);
  };

  const validateFields = useCallback(() => {
    let hasError = false;
    let messages = [];
    if (name.length === 0) {
      hasError = true;
      messages.push(<li>Nome</li>);
    }
    if (description.length === 0) {
      hasError = true;
      messages.push(<li>Descrição</li>);
    }

    if (fileList.length === 0) {
      hasError = true;
      messages.push(<li>Imagem</li>);
    }

    if (hasError) {
      toast.error(
        <div>
          Campos obrigatorios:
          <br />
          {messages.map((message) => {
            return <div>{message}</div>;
          })}
        </div>
      );
      return false;
    }
    return true;
  }, [description, fileList, name]);

  const handleSubmit = useCallback(() => {
    console.log("Submit");
    const userId = user?._id.toString() || "";
    const files: File[] = fileList.map((item) => item.response.file);
    if (id) {
      updateDocument(name, description, id as string, files as any);
      return;
    }
    addDocumentRequest(name, description, files[0], userId);
  }, [
    validateFields,
    fileList,
    documentId,
    name,
    description,
    addDocumentRequest,
    user,
  ]);

  const handleName = (e: any) => {
    const typedName = e.target.value;
    let updatedName = typedName.slice(0, 50);
    setName(updatedName);
  };

  return (
    <div className={styles.container}>
      {documentId && !initialData ? (
        <Result
          status="404"
          title="404"
          subTitle="Desculpe, a página que você visitou não existe."
        />
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.row}>
              <ArrowLeft onClick={() => navigate(-1)} className={styles.icon} />
              <div className={styles.mainTitle}>Adicionar Documento</div>
            </div>
            <CustomButton onClick={handleSubmit}>
              <div className={styles.button}>
                Salvar <CheckCheck />
              </div>
            </CustomButton>
          </div>
          <div className={styles.body}>
            <div className={styles.row}>
              <div className={styles.formGroup}>
                <div className={styles.label}>Nome</div>
                <InputTextSimple
                  name="name"
                  value={name}
                  placeholder="Insira o nome"
                  onChange={handleName}
                  maxLength={50}
                />
              </div>

              <div className={styles.formGroup}>
                <div className={styles.label}>Descrição</div>
                <InputTextSimple
                  name="description"
                  value={description}
                  placeholder="Insira a descrição"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>

            <div className={styles.formGroup}>
              <div className={styles.label}>Imagens</div>
              <Upload
                listType="picture"
                onChange={handleChange}
                maxCount={1}
                showUploadList={{
                  removeIcon: <Trash size={14} className={styles.icon} />,
                }}
                fileList={fileList}
                customRequest={(e) => {
                  if (e.onSuccess) e.onSuccess(e);
                }}
              >
                {fileList.length < 1 && (
                  <CustomButton>
                    <div className={styles.button}>
                      Upload <UploadIcon />
                    </div>
                  </CustomButton>
                )}
              </Upload>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AddDocuments;
