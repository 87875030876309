import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Result, Spin } from "antd";
import { XOctagon, Info } from "lucide-react";
import queryString from "query-string";
import axios from "axios";

import { API_URL } from "../../constants/env";

function EmailConfirmation(): JSX.Element {
  const { search } = useLocation();
  const { token } = queryString.parse(search);
  const navigate = useNavigate();
  const { id } = queryString.parse(search);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({
    title: "Sucesso, sua conta foi ativada!",
    subTitle: "Você será redirecionado em instantes para a página de login!",
    button: "Se prefeir ir agora, clique aqui!",
    status: "success",
  });

  async function emailConfirm() {
    try {
      const options = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      await axios.put(
        `${API_URL}user/${id}`,
        {
          active: true,
        },
        options
      );

      toast.success(
        "Email confirmado, você será redirecionado para a página de login em instantes...",
        { position: "top-center", autoClose: 5000, hideProgressBar: true }
      );

      setTimeout(() => {
        navigate(`/login`);
      }, 800);
    } catch (error) {
      setStatus({
        title: "Não foi possível confirmar o seu email ",
        subTitle: "Contate o suporte.",
        button: "Clique aqui para ser redirecionado para a pagina de login.",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    emailConfirm();
  }, []);

  return (
    <div>
      <Result
        title={status.title}
        subTitle={status.subTitle}
        icon={
          status.status === "error" ? (
            <XOctagon size={200} color="red" />
          ) : (
            <Info size={200} color="blue" />
          )
        }
        extra={[
          <Button type="primary" onClick={() => navigate(`/login`)}>
            {status.button}
          </Button>,
        ]}
      />
      <Spin spinning={loading} fullscreen />;
    </div>
  );
}

export default EmailConfirmation;
