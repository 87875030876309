import Filters from "../../../../libs/Filters";
import { FormDataTransaction } from "../types";
import { calculateInstallments } from "../utils/calculators";
import {
  Address,
  ApiPayload,
  CreditCard,
  Customer,
  Item,
  PixPayment,
  Shipping,
} from "./types";

const formatData = (
  formData: FormDataTransaction,
  cardDetails: {
    creditCardNumber: string;
    expirationDate: string;
    cardHolderName: string;
    cvc: string;
  },
  paymentMethod: "pix" | "creditCard",
  installments: number,
  produtct: any,
  shippingValue: number
): ApiPayload => {
  const [expMonth, expYear] = cardDetails.expirationDate.split("/").map(Number);

  const phoneNumberOnlyNumbers = Filters.clearStringOnlyNumbers(formData.phone);

  const address: Address = {
    country: "BR",
    state: formData.stateSelected.toString(),
    city: formData.citiesSelected.toString(),
    zip_code: formData.cep,
    line_1: formData.logradouro,
    line_2: formData.numero,
  };

  const customer: Customer = {
    address,
    phones: {
      mobile_phone: {
        country_code: "55",
        area_code: phoneNumberOnlyNumbers.substring(0, 2),
        number: phoneNumberOnlyNumbers.substring(2),
      },
    },
    name: formData.name,
    type: "individual",
    email: formData.email,
    code: `${Math.random()}`,
    document: formData.cpf.replace(/\D/g, ""),
    document_type: "CPF",
    password: "",
  };

  const shipping: Shipping = {
    address,
    amount: Math.round(shippingValue * 100) || 1,
    description: produtct?.name,
    recipient_name: formData.name,
    recipient_phone: formData.phone,
  };

  const items: Item[] = [
    {
      amount:
        installments > 1
          ? calculateInstallments(produtct?.price)[installments - 1].total
          : Math.round(produtct?.price * 100) || 0,
      description: produtct?.name,
      quantity: 1,
      code: produtct?._id,
    },
  ];

  const payments: PixPayment[] | CreditCard[] =
    paymentMethod === "pix"
      ? [
          {
            payment_method: "pix",
            Pix: {
              expires_in: 7200, // 2 horas
            },
          },
        ]
      : [
          {
            payment_method: "credit_card",
            credit_card: {
              recurrence: false,
              installments,
              card: {
                number: cardDetails.creditCardNumber.replace(/\s+/g, ""),
                holder_name: cardDetails.cardHolderName,
                exp_month: expMonth,
                exp_year: expYear,
                cvv: cardDetails.cvc,
                billing_address: address,
              },
            },
          },
        ];

  return {
    customer,
    shipping,
    items,
    payments,
  };
};

export default formatData;
