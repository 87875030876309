import { UploadFile } from "antd";
import { AddCrudProduct, EditCrudProduct, IFile } from "../../../../contexts";
import { FieldType } from "./types";

export function format(
  values: FieldType,
  fileList: UploadFile[]
): AddCrudProduct {
  const files: File[] = fileList
    .filter((item) => !!item?.response?.file)
    .map((item) => item.response.file);
  let options: AddCrudProduct["options"];

  if (values.addSizeOptions) {
    options = [
      {
        title: "Tamanho",
        description: "Escolha seu tamanho.",
        choices: ["P", "M", "G", "GG"],
      },
    ];
  } else {
    options = undefined;
  }

  return {
    name: values.name,
    description: values.description,
    price: values.price,
    auff: values.auff,
    createUser: values.createUser || false,
    commissionDistributionCarrer: [
      values.groupValue1,
      values.groupValue2,
      values.groupValue3,
    ],
    commissionType: values.commissionType,
    presentialProduct: values.productType === "presencial" ? true : false,
    isCommissionable: values.isCommissionable,
    digitalProduct: values.productType === "digital" ? true : false,
    freeShipping: values.freeShipping,
    recurrence: values.recurrence,
    recorrentPayment: values.recurrenceType,
    isCademi: values.isCademi,
    cademiKey: values.cademiKey,
    directCommissionValue: values.directCommissionValue,
    shippingValues: {
      width: values.width,
      height: values.height,
      length: values.length,
      weight: values.weight,
    },
    options,
    files,
  };
}

export function formatForUpdate(
  values: FieldType,
  fileList: UploadFile[],
  existingFiles: IFile[],
  id: string
): EditCrudProduct {
  const newFiles: File[] = fileList
    .filter((item) => !!item?.originFileObj)
    .map((item) => item.originFileObj as File);

  const removedFiles: IFile[] = existingFiles.filter(
    (file) => !fileList.some((item) => item.name === file.name)
  );

  let optionsToUpdate: AddCrudProduct["options"];

  if (values.addSizeOptions) {
    optionsToUpdate = [
      {
        title: "Tamanho",
        description: "Escolha seu tamanho.",
        choices: ["P", "M", "G", "GG"],
      },
    ];
  } else {
    optionsToUpdate = undefined;
  }

  return {
    id: id,
    name: values.name,
    description: values.description,
    price: values.price,
    auff: values.auff,
    createUser: values.createUser || false,
    commissionDistributionGroup: [
      values.groupValue1 || 0,
      values.groupValue2 || 0,
      values.groupValue3 || 0,
    ],
    commissionDistributionSpheres: [
      values.sphereValue1 || 0,
      values.sphereValue2 || 0,
      values.sphereValue3 || 0,
    ],
    commissionType: values.commissionType,
    presentialProduct: values.productType === "presencial" ? true : false,
    isCommissionable: values.isCommissionable,
    digitalProduct: values.productType === "digital" ? true : false,
    freeShipping: values.freeShipping,
    recurrence: values.recurrence,
    recorrentPayment: values.recurrenceType,
    isCademi: values.isCademi,
    cademiKey: values.cademiKey,
    directCommissionValue: values.directCommissionValue,
    options: optionsToUpdate,
    shippingValues: {
      width: values.width,
      height: values.height,
      length: values.length,
      weight: values.weight,
    },
    newFiles,
    removedFiles,
  };
}

export function filesHandler(fileList: UploadFile[]) {
  const files: File[] = fileList
    .filter((item) => !!item?.response?.file)
    .map((item) => item.response.file);

  return files;
}
