import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal, Space, Table } from "antd";
import { Eye } from "lucide-react";
import { ColumnsType } from "antd/es/table";

import { ContextApi, IUserReport } from "../../../contexts";
import { mainScreemDetails } from "../../../services/requests/main";
import FiltersComponent from "./components/filters";

import styles from "./styleUsers.module.scss";
import { filterOptions, activeOptions } from "./users.const";
import UsersInfoModal from "./components/users-info.modal";

interface DataType {
  avatar: string;
  name: string;
}

const Users: React.FC = () => {
  const {
    getAllUsers,
    users,
    dimensions,
    getAllTransactionsByUserId,
    getSpheresByUser,
  } = useContext(ContextApi);

  const [modal, setModal] = useState(false);
  const [filterBy, setFilterBy] = useState<"name" | "active" | "graduation">(
    "name"
  );
  const [filter, setFilter] = useState("");
  const [currentUser, setCurrentUser] = useState<IUserReport | undefined>(
    undefined
  );
  const [apiData, setApiData] = useState<any>(null);
  const [email, setEmail] = useState("");
  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  useEffect(() => {
    if (currentUser) {
      fetchDataForCurrentUser(currentUser._id);
      setEmail(currentUser.email);
    }
  }, [currentUser, getAllTransactionsByUserId, getSpheresByUser]);

  const fetchDataForCurrentUser = async (userId: string) => {
    try {
      await Promise.all([
        getAllTransactionsByUserId(userId),
        getSpheresByUser(userId),
        mainScreemDetails(userId).then((response: any) => {
          setApiData(response.data as any);
        }),
      ]);
    } catch (error) {
      console.error("Erro ao buscar dados", error);
    }
  };

  const filteredUsers = useMemo(() => {
    if (filter === "") return users;
    if (filterBy === "active") {
      return users.filter((u) => u.active === (filter === "true"));
    } else {
      return users.filter((u) =>
        u[filterBy]?.toLowerCase().includes(filter.toLowerCase())
      );
    }
  }, [filterBy, filter, users]);

  const columns: ColumnsType<DataType> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Ações",
      key: "action",
      render: (data) => (
        <Space size="middle">
          <Eye
            onClick={() => {
              setCurrentUser(data);
              setModal(!modal);
            }}
            className={styles.icon}
          />
        </Space>
      ),
    },
  ];

  if (dimensions.width > 768) {
    columns.splice(2, 0, {
      title: "Graduação",
      dataIndex: "graduation",
      key: "graduation",
      render: (data) => (
        <div style={{ textTransform: "capitalize" }}>{data?.toLowerCase()}</div>
      ),
    });
    columns.splice(3, 0, {
      title: "Status",
      dataIndex: "active",
      key: "active",
      render: (data) => (
        <div style={{ textTransform: "capitalize" }}>
          {data ? "ativo" : "inativo"}
        </div>
      ),
    });
  }

  const renderStatistics = () => {
    if (dimensions.width > 768) {
      return (
        <div className={styles.row}>
          <div className={styles.badge}>
            Usuários ativos: {users.filter((u) => u.active).length}
          </div>
          <div className={styles.badge}>Qtd usuários: {users.length}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Usuários</div>
      </div>
      <div className={styles.filtersRow}>
        <FiltersComponent
          filterOptions={filterOptions}
          activeOptions={activeOptions}
          filterBy={filterBy}
          filter={filter}
          setFilterBy={setFilterBy}
          setFilter={setFilter}
        />
        {renderStatistics()}
      </div>
      <Table
        pagination={{ pageSize: 5 }}
        columns={columns}
        dataSource={filteredUsers as any}
      />
      <Modal
        centered
        className={styles.scroll}
        open={modal}
        width={dimensions.width > 768 ? "50vw" : "95vw"}
        styles={{ body: { height: "85vh", overflow: "scroll" } }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        onCancel={() => {
          setModal(!modal);
          setCurrentUser(undefined);
        }}
      >
        <UsersInfoModal
          currentUser={currentUser}
          email={email}
          setEmail={setEmail}
          fetchDataForCurrentUser={fetchDataForCurrentUser}
          apiData={apiData}
        />
      </Modal>
    </div>
  );
};

export default Users;
