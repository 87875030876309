import api from "../api";

export async function getCommissionsByUserId(userId: string) {
  return new Promise((resolve, reject) => {
    api
      .get(`commission/single/${userId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getAllCommissions() {
  return new Promise((resolve, reject) => {
    api
      .get(`commission/all`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getSellingReports(id: string) {
  return await api.get(`commission/report/seller/${id}`);
}

export const getSellingReportsFile = async (userId: string, format: string) => {
  const response = await api.get(
    `/commission/report/seller/${userId}?format=${format}`,
    {
      responseType: "blob",
    }
  );
  return response;
};
