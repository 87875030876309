import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Space,
  Spin,
  Switch,
  Upload,
  UploadFile,
  message,
  FormProps,
  UploadProps,
  Radio,
} from "antd";

import styles from "./stylesAddProducts.module.scss";
import { EditCrudProduct, IFile } from "../../../../contexts";
import { UploadIcon } from "lucide-react";
import { getProductById } from "../../../../services/requests/products";
import { FieldType, ModalUpdateProductsProps, SizeType } from "./types";
import { formatForUpdate } from "./formatter";
import { UploadChangeParam } from "antd/es/upload";
import editProductRequest from "./update-product-handler";

const ModalUpdateProducts: React.FC<ModalUpdateProductsProps> = ({
  close,
  productId,
}) => {
  const [formSize, setFormSize] = useState<SizeType | "default">("default");
  const [flags, setFlags] = useState({
    directCommission: false,
    isCademi: false,
    isRecurrent: false,
    addSizeOptions: false,
    isGroupCommission: false,
    isSphereCommission: false,
  });
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [existingFiles, setExistingFiles] = useState<IFile[]>([]);
  const [form] = Form.useForm();

  function getProductType(digital: boolean, presencial: boolean): string {
    if (digital) {
      return "digital";
    } else if (presencial) {
      return "presencial";
    } else {
      return "fisico";
    }
  }

  useEffect(() => {
    const fetchProductData = async () => {
      setLoading(true);
      try {
        const { data }: any = await getProductById(productId);
        form.setFieldsValue({
          ...data.product,
          height: data.product.shippingValues.height,
          weight: data.product.shippingValues.weight,
          width: data.product.shippingValues.width,
          length: data.product.shippingValues.length,
          addSizeOptions: data.product.options.length > 0 ? true : false,
          productType: getProductType(
            data.product.digitalProduct,
            data.product.presentialProduct
          ),
          recurrence: data.product.recorrentPaymentType,
          groupValue1: data.product.commissionDistributionGroup[0],
          groupValue2: data.product.commissionDistributionGroup[1],
          groupValue3: data.product.commissionDistributionGroup[2],
          sphereValue1: data.product.commissionDistributionSpheres[0],
          sphereValue2: data.product.commissionDistributionSpheres[1],
          sphereValue3: data.product.commissionDistributionSpheres[2],
        });
        setFlags({
          directCommission: data.product.isCommissionable,
          isCademi: data.product.isCademi,
          isRecurrent: data.product.recorrentPayment,
          addSizeOptions: data.product.options.length > 0 ? true : false,
          isGroupCommission:
            data.product.commissionType === "grupo" ? true : false,
          isSphereCommission:
            data.product.commissionType === "esfera" ? true : false,
        });
        setFileList(
          data.imageUrls.map((img: string) => ({ url: img, name: img }))
        ); // Assuming images are URLs
        setExistingFiles(
          data.imageUrls.map((img: string) => ({ url: img, name: img }))
        ); // Assuming images are URLs
      } catch (error) {
        console.log(error);
        message.error("Failed to load product data");
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, [productId, form]);

  const handleChange: UploadProps["onChange"] = useCallback(
    (info: UploadChangeParam<UploadFile<any>>) => {
      const newFileList = info.fileList.map((file) => {
        if (file.response) {
          file.url = URL.createObjectURL(file.response.file);
        }
        return file;
      });
      setFileList(newFileList);
    },
    []
  );

  const onFormLayoutChange = useCallback(({ size }: { size: SizeType }) => {
    setFormSize(size);
  }, []);

  const handleFinish: FormProps<FieldType>["onFinish"] = useCallback(
    async (values: FieldType) => {
      const payload: EditCrudProduct = formatForUpdate(
        values,
        fileList,
        existingFiles,
        productId
      );

      setLoading(true);
      await editProductRequest(payload);

      setLoading(false);
      close(true);
    },
    [close, fileList, existingFiles, productId]
  );

  const handleFinishFailed: FormProps<FieldType>["onFinishFailed"] =
    useCallback((errorInfo: any) => {
      console.log("Failed:", errorInfo);
    }, []);

  const toggleFlag = useCallback(
    (flag: keyof typeof flags) => {
      setFlags((prevFlags) => ({
        ...prevFlags,
        [flag]: !prevFlags[flag],
      }));
    },
    [flags]
  );

  const setCommissionType = useCallback((type: "grupo" | "esfera") => {
    setFlags((prevFlags) => ({
      ...prevFlags,
      isGroupCommission: type === "grupo",
      isSphereCommission: type === "esfera",
    }));
  }, []);

  return (
    <div className={styles.content}>
      <Spin spinning={loading}>
        <Form
          form={form}
          labelCol={{ span: 4.5 }}
          wrapperCol={{ span: 18 }}
          layout="vertical"
          initialValues={{ size: formSize }}
          onValuesChange={onFormLayoutChange}
          size={formSize as SizeType}
          style={{ maxWidth: 600 }}
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
        >
          <Form.Item
            label="Nome"
            name="name"
            rules={[{ required: true, message: "Campo obrigatório!" }]}
          >
            <Input
              style={{ width: "580px", height: "50px" }}
              placeholder="Nome do produto"
            />
          </Form.Item>
          <Form.Item
            label="Descrição"
            name="description"
            rules={[{ required: true, message: "Campo obrigatório!" }]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Descrição do produto"
              style={{ maxWidth: "900px", width: "580px" }}
            />
          </Form.Item>
          <Space.Compact>
            <Form.Item
              label="Preço"
              name="price"
              rules={[{ required: true, message: "Campo obrigatório!" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Valor do produto"
              />
            </Form.Item>
            <Form.Item
              label="AUFFS"
              name="auff"
              rules={[{ required: true, message: "Campo obrigatório!" }]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Auffs gerados pelo produto"
              />
            </Form.Item>
            <Form.Item
              label="Tipo de produto"
              name="productType"
              style={{ width: "100%" }}
              rules={[
                { required: true, message: "Selecione o tipo do produto!" },
              ]}
            >
              <Radio.Group style={{ width: "110%" }}>
                <Radio value="fisico"> Físico </Radio>
                <Radio value="digital"> Digital </Radio>
                <Radio value="presencial"> Presencial </Radio>
              </Radio.Group>
            </Form.Item>
          </Space.Compact>

          <Space.Compact>
            <Form.Item
              label="Gera usuário?"
              name="createUser"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Opções de tamanho?"
              name="addSizeOptions"
              valuePropName="checked"
            >
              <Switch onChange={() => toggleFlag("addSizeOptions")} />
            </Form.Item>
            <Form.Item
              label="Cobrança recorrente?"
              name="recurrenceType"
              valuePropName="checked"
            >
              <Switch onChange={() => toggleFlag("isRecurrent")} />
            </Form.Item>
          </Space.Compact>
          <Space.Compact>
            <Form.Item
              label="Entrega Cademi?"
              name="isCademi"
              valuePropName="checked"
            >
              <Switch onChange={() => toggleFlag("isCademi")} />
            </Form.Item>

            <Form.Item
              label="Comissão direta?"
              name="isCommissionable"
              valuePropName="checked"
            >
              <Switch onChange={() => toggleFlag("directCommission")} />
            </Form.Item>
          </Space.Compact>
          {flags.directCommission && (
            <Form.Item
              label="Comissão direta (valor)"
              name="directCommissionValue"
              style={{ width: "100%" }}
            >
              <InputNumber
                disabled={!flags.directCommission}
                style={{ width: "100%" }}
                placeholder="Comissão direta"
              />
            </Form.Item>
          )}

          {flags.isRecurrent && (
            <Form.Item
              label="Tipo de recorrência"
              name="recurrence"
              rules={[
                {
                  required: flags.isRecurrent,
                  message: "Campo obrigatório!",
                },
              ]}
            >
              <Radio.Group>
                <Radio value="mensal"> Mensal </Radio>
                <Radio value="anual"> Anual </Radio>
              </Radio.Group>
            </Form.Item>
          )}
          {flags.isCademi && (
            <Form.Item
              label="Chave cademi"
              name="cademiKey"
              style={{ width: "100%" }}
            >
              <Input
                disabled={!flags.isCademi}
                style={{ width: "100%" }}
                placeholder="Chave Cademi"
              />
            </Form.Item>
          )}
          <Form.Item label="Tipo de comissão" name="commissionType">
            <Radio.Group
              onChange={(e) => {
                const type = e.target.value;
                setCommissionType(type);
              }}
            >
              <Radio value="grupo"> Grupo </Radio>
              <Radio value="esfera"> Esfera </Radio>
            </Radio.Group>
          </Form.Item>
          {flags.isGroupCommission && (
            <>
              {" "}
              <label>Porcentagem da Comissão (Grupo)</label>
              <Space.Compact style={{ marginTop: "2%" }}>
                <Form.Item
                  name="groupValue1"
                  rules={[
                    {
                      required: flags.isGroupCommission,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="groupValue2"
                  rules={[
                    {
                      required: flags.isGroupCommission,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="groupValue3"
                  rules={[
                    {
                      required: flags.isGroupCommission,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Space.Compact>
            </>
          )}
          {flags.isSphereCommission && (
            <>
              <label>Porcentagem da Comissão (Esferas)</label>
              <Space.Compact style={{ marginTop: "2%" }}>
                <Form.Item
                  name="sphereValue1"
                  rules={[
                    {
                      required: flags.isSphereCommission,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="sphereValue2"
                  rules={[
                    {
                      required: flags.isSphereCommission,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                  name="sphereValue3"
                  rules={[
                    {
                      required: flags.isSphereCommission,
                      message: "Campo obrigatório!",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Space.Compact>{" "}
            </>
          )}
          <Form.Item label="Envio" style={{ width: "100%" }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Form.Item label="Altura" name="height">
                <InputNumber
                  placeholder="Altura (cm)"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item label="Largura" name="width">
                <InputNumber
                  placeholder="Largura (cm)"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item label="Comprimento" name="length">
                <InputNumber
                  placeholder="Comprimento (cm)"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item label="Peso" name="weight">
                <InputNumber placeholder="Peso (g)" style={{ width: "100%" }} />
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item style={{ width: "100%" }}>
            <Upload
              fileList={fileList}
              onChange={handleChange}
              beforeUpload={() => false}
              multiple
              listType="picture"
              accept=".png,.jpg,.jpeg"
            >
              {fileList.length < 10 && (
                <div className={styles.upload}>
                  <strong>
                    Clique aqui ou arraste uma imagem para adicionar
                  </strong>
                  <UploadIcon color="#f04e23" style={{ marginLeft: 10 }} />
                </div>
              )}
            </Upload>
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Atualizar Produto
              </Button>
              <Button onClick={() => close(false)}>Cancelar</Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default ModalUpdateProducts;
