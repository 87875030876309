import { AddCrudProduct, EditCrudProduct } from "../../contexts";

import api from "../api";

export async function getProducts() {
  return new Promise((resolve, reject) => {
    api
      .get("products")
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function getProductById(id: string) {
  return new Promise((resolve, reject) => {
    api
      .get(`products/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function deleteProduct(id: string) {
  return new Promise((resolve, reject) => {
    api
      .delete(`products/${id}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function deleteProductV2(id: string) {
  return await api.delete(`products/${id}`);
}

export async function addProduct(product: AddCrudProduct) {
  return new Promise((resolve, reject) => {
    const {
      name,
      description,
      price,
      auff,
      createUser,
      commissionDistributionSpheres,
      commissionDistributionGroup,
      commissionDistributionCarrer,
      commissionType,
      shippingValues,
      isCommissionable,
      directCommissionValue,
      digitalProduct,
      freeShipping,
      recurrence,
      isCademi,
      cademiKey,
    } = product;
    api
      .post("products", {
        name,
        description,
        price,
        auff,
        createUser,
        commissionDistributionSpheres,
        commissionDistributionGroup,
        commissionDistributionCarrer,
        commissionType,
        shippingValues,
        isCommissionable,
        directCommissionValue,
        digitalProduct,
        freeShipping,
        recurrence,
        isCademi,
        cademiKey,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function addProductV2(product: AddCrudProduct) {
  const {
    name,
    description,
    price,
    auff,
    createUser,
    commissionDistributionSpheres,
    commissionDistributionGroup,
    commissionDistributionCarrer,
    commissionType,
    shippingValues,
    isCommissionable,
    directCommissionValue,
    digitalProduct,
    freeShipping,
    recurrence,
    isCademi,
    cademiKey,
    recorrentPayment,
    presentialProduct,
    options,
  } = product;
  return await api.post("products", {
    name,
    description,
    price,
    auff,
    createUser,
    commissionDistributionSpheres,
    commissionDistributionGroup,
    commissionDistributionCarrer,
    commissionType,
    shippingValues,
    isCommissionable,
    directCommissionValue,
    digitalProduct,
    freeShipping,
    recorrentPaymentType: recurrence,
    isCademi,
    cademiKey,
    recorrentPayment,
    presentialProduct,
    options,
  });
}

export async function editProductV2(product: EditCrudProduct) {
  const {
    id,
    name,
    description,
    price,
    auff,
    createUser,
    commissionDistributionSpheres,
    commissionDistributionGroup,
    commissionDistributionCarrer,
    commissionType,
    shippingValues,
    isCommissionable,
    directCommissionValue,
    digitalProduct,
    freeShipping,
    recurrence,
    isCademi,
    cademiKey,
    presentialProduct,
  } = product;
  return await api.put(`products/${id}`, {
    name,
    description,
    price,
    auff,
    createUser,
    commissionDistributionSpheres,
    commissionDistributionGroup,
    commissionDistributionCarrer,
    commissionType,
    shippingValues,
    isCommissionable,
    directCommissionValue,
    digitalProduct,
    freeShipping,
    recurrence,
    isCademi,
    cademiKey,
    presentialProduct,
  });
}

export async function editProduct(product: EditCrudProduct) {
  return new Promise((resolve, reject) => {
    const {
      id,
      name,
      description,
      price,
      auff,
      createUser,
      commissionDistributionSpheres,
      commissionDistributionGroup,
      commissionDistributionCarrer,
      commissionType,
      shippingValues,
      isCommissionable,
      directCommissionValue,
      digitalProduct,
      freeShipping,
      recurrence,
      isCademi,
      cademiKey,
      options,
    } = product;
    api
      .put(`products/${id}`, {
        name,
        description,
        price,
        auff,
        createUser,
        commissionDistributionSpheres,
        commissionDistributionGroup,
        commissionDistributionCarrer,
        commissionType,
        shippingValues,
        isCommissionable,
        directCommissionValue,
        digitalProduct,
        freeShipping,
        recurrence,
        isCademi,
        cademiKey,
        options,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
