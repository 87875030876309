import { configureStore } from "@reduxjs/toolkit";
import ProductsReducer from "./products";
import TransactionsReducer from "./transactions";
import AuthReducer from "./auth";

export default configureStore({
  reducer: {
    products: ProductsReducer,
    transactions: TransactionsReducer,
    auth: AuthReducer,
  },
});
