import React, { useEffect, useState } from "react";
import styles from "../styles.module.scss";
import { Divider, Radio, Space } from "antd";
import { CustomButton } from "../../../components/buttons/customButton";
import { InputSimpleSelect } from "../../../components/inputs/simpleSelect/simpleSelectInput";
import { InputTextSimple } from "../../../components/inputs/simpleText/inputSimpleText";
import Filters from "../../../libs/Filters";
import { ProductDetailsContentProps } from "./types";

const SellingInformation: React.FC<ProductDetailsContentProps> = ({
  ufs,
  cities,
  product,
  formData,
  changeWidthInput,
  currentScreen,
  onOptionsUpdate,
  getAdressByPostalCode,
  calcShipping,
  getCitiesByUf,
  shipingCost,
  onProceed,
  saleIdentification,
  shippingPrice,
  setShippingPrice,
}) => {
  const [metadataShippingService, setMetadataShippingService] = useState({
    id: 0,
    name: "",
    price: 0,
    company: {
      id: 0,
      name: "",
      picture: "",
    },
    shippingValues: {
      width: 0,
      height: 0,
      length: 0,
      weight: 0,
    },
  });

  useEffect(() => {
    updateMetadataShippingService();
  }, [shippingPrice, product]);

  useEffect(() => {
    updateFormData();
  }, [metadataShippingService, shippingPrice]);

  const updateMetadataShippingService = () => {
    const idSimulacao = shippingPrice?.idSimulacao ?? 0;
    const descricao = shippingPrice?.descricao ?? "";
    const vlrFrete = shippingPrice?.vlrFrete ?? 0;
    const transp_nome = shippingPrice?.transp_nome ?? "";
    const url_logo = shippingPrice?.url_logo ?? "";

    setMetadataShippingService({
      id: idSimulacao,
      name: descricao,
      price: vlrFrete,
      company: {
        id: idSimulacao,
        name: transp_nome,
        picture: url_logo,
      },
      shippingValues: {
        width: product?.shippingValues?.width || 0,
        height: product?.shippingValues?.height || 0,
        length: product?.shippingValues?.length || 0,
        weight: product?.shippingValues?.weight || 0,
      },
    });
  };

  const updateFormData = () => {
    formData.shippingSelected = shippingPrice.id;
    formData.metadataShipping = metadataShippingService;

    const payloadUpdated = {
      ...formData,
      shippingSelected: shippingPrice.id,
      metadataShipping: metadataShippingService,
    };

    onOptionsUpdate(payloadUpdated);
  };

  const calculateTotalPrice = () => {
    const totalShippingPrice =
      shippingPrice?.vlrFrete || parseFloat(shippingPrice?.price) || 0;
    return (product?.price || 0) + totalShippingPrice;
  };

  const onShippingSelected = (id: number, type: string) => {
    if (type === "kangu") {
      const shipping = shipingCost?.find((item) => item.idSimulacao === id);
      if (shipping) {
        setShippingPrice(shipping);
      }
    } else if (type === "melhorenvio") {
      const shipping = shipingCost?.find((item: any) => item.id === id);
      console.log(shipping);
      if (shipping) {
        setShippingPrice(shipping);
      }
    }
  };

  const formatShippingOptions = (item: any, type: string) => {
    if (type === "kangu") {
      return {
        id: item.idSimulacao,
        nome: `${item.descricao} - ${Filters.convertMoneyTextMask(
          item.vlrFrete
        )} - Prazo aproximado até ${item.prazoEnt} dias úteis`,
      };
    } else if (type === "melhorenvio") {
      return {
        id: item.id,
        nome: `${item.name} - ${Filters.convertMoneyTextMask(
          item.custom_price
        )} - Prazo aproximado até ${item.delivery_time} dias úteis`,
      };
    }
  };

  const shippingOptions = shipingCost.filter(
    (option: any) => option.name === "SEDEX" || option.name === "PAC"
  );

  const renderProductOptions = () => (
    <Space wrap>
      {product?.options?.map((option: { choices: string[]; title: string }) => {
        const formatOptions = option.choices.map((choice: string) => ({
          value: choice,
          label: choice,
        }));
        const changeOption = (e: any) => {
          const payload = {
            option: option.title,
            value: e.target.value,
          };
          onOptionsUpdate({ ...formData, options: payload });
        };

        return (
          <div key={option.title}>
            <p
              style={{
                textTransform: "capitalize",
                fontSize: "16px",
                fontWeight: "500",
                padding: "5px",
              }}
            >
              {option.title}
            </p>
            <Radio.Group
              options={formatOptions}
              optionType="button"
              buttonStyle="solid"
              onChange={changeOption}
            />
          </div>
        );
      })}
    </Space>
  );

  const renderIdentificationForm = () => (
    <div className={styles.boxInputs}>
      <h3>Dados de identificação</h3>
      <div className={styles.formLine1}>
        <InputTextSimple
          name="name"
          value={formData.name}
          placeholder="Nome completo"
          onChange={(e) =>
            onOptionsUpdate({ ...formData, name: e.target.value })
          }
          style={changeWidthInput(currentScreen, "49%")}
          onPaste={(e) => e.preventDefault()}
          autoComplete="one-time-code"
        />
        <InputTextSimple
          name="cpf"
          value={Filters.inputMaskCPFCNPJ(formData.cpf)}
          placeholder="Insira seu CPF"
          error={formData.error}
          onChange={(e) =>
            onOptionsUpdate({ ...formData, cpf: e.target.value })
          }
          style={changeWidthInput(currentScreen, "49%")}
          onPaste={(e) => e.preventDefault()}
          autoComplete="one-time-code"
        />
      </div>
      <div className={styles.formLine2}>
        <InputTextSimple
          name="phoneNumber"
          value={Filters.inputMaskTELWithDDD(formData.phone)}
          placeholder="Insira seu telefone"
          onChange={(e) =>
            onOptionsUpdate({ ...formData, phone: e.target.value })
          }
          style={changeWidthInput(currentScreen, "49%")}
          onPaste={(e) => e.preventDefault()}
          autoComplete="one-time-code"
        />
        <InputTextSimple
          name="email"
          value={formData.email}
          placeholder="Insira seu email"
          onChange={(e) =>
            onOptionsUpdate({ ...formData, email: e.target.value })
          }
          style={changeWidthInput(currentScreen, "49%")}
          onPaste={(e) => e.preventDefault()}
          autoComplete="one-time-code"
        />
      </div>
    </div>
  );

  const renderAddressForm = () => (
    <div className={styles.boxInputs}>
      <h3>Endereço de entrega</h3>
      <div className={styles.formLine1}>
        <InputTextSimple
          name="cep"
          value={Filters.inputMaskCEP(formData.cep)}
          placeholder="Insira seu CEP"
          onChange={(e) => {
            const newCep = e.target.value;
            onOptionsUpdate({ ...formData, cep: newCep });
            if (newCep.length === 8) {
              const cepString =
                Filters.clearStringOnlyNumbers(newCep).toString();
              getAdressByPostalCode(cepString);
              if (product.shippingValues?.height) {
                calcShipping(cepString);
              }
            }
          }}
          style={changeWidthInput(currentScreen, "32%")}
          onPaste={(e) => e.preventDefault()}
          autoComplete="one-time-code"
        />
        <InputSimpleSelect
          optionZero="Selecione seu estado"
          data={ufs}
          style={changeWidthInput(currentScreen, "32%")}
          onChange={(e) => {
            onOptionsUpdate({
              ...formData,
              stateSelected: Number(e.target.value),
            });
            getCitiesByUf(e.target.value);
          }}
          value={formData.stateSelected}
          disabled={formData.cep.length !== 8}
        />
        <InputSimpleSelect
          optionZero="Selecione sua cidade"
          autoComplete="off"
          data={cities}
          style={changeWidthInput(currentScreen, "32%")}
          onChange={(e) => {
            onOptionsUpdate({
              ...formData,
              citiesSelected: e.target.value,
            });
          }}
          value={formData.citiesSelected}
          disabled={formData.cep.length !== 8}
        />
      </div>
      <div className={styles.formLine2}>
        <InputTextSimple
          name="logradouro"
          value={formData.logradouro}
          placeholder="Logradouro"
          onChange={(e) =>
            onOptionsUpdate({ ...formData, logradouro: e.target.value })
          }
          style={changeWidthInput(currentScreen, "49%")}
          disabled={formData.cep.length !== 8}
        />
        <InputTextSimple
          name="number"
          value={formData.numero}
          placeholder="Número"
          onChange={(e) =>
            onOptionsUpdate({ ...formData, numero: e.target.value })
          }
          style={changeWidthInput(currentScreen, "14%")}
          disabled={formData.cep.length !== 8}
        />
        <InputTextSimple
          name="bairro"
          value={formData.bairro}
          placeholder="Bairro"
          onChange={(e) =>
            onOptionsUpdate({ ...formData, bairro: e.target.value })
          }
          style={changeWidthInput(currentScreen, "35%")}
          disabled={formData.cep.length !== 8}
        />
      </div>
      <div className={styles.formLine2}>
        <InputTextSimple
          name="complemento"
          value={formData.complemento}
          placeholder="Complemento"
          onChange={(e) =>
            onOptionsUpdate({ ...formData, complemento: e.target.value })
          }
          style={changeWidthInput(currentScreen, "49%")}
          disabled={formData.cep.length !== 8}
        />
        <InputTextSimple
          name="referencia"
          value={formData.referencia}
          placeholder="Ponto de referência"
          onChange={(e) =>
            onOptionsUpdate({ ...formData, referencia: e.target.value })
          }
          style={changeWidthInput(currentScreen, "49%")}
          disabled={formData.cep.length !== 8}
        />
      </div>

      {!product?.freeShipping && product.shippingValues?.height ? (
        <div className={styles.formLine2}>
          <InputSimpleSelect
            optionZero="Selecione a forma de entrega"
            data={shippingOptions?.map((item) => {
              return formatShippingOptions(item, "melhorenvio");
            })}
            optionStyle={{
              color: "#000",
              fontWeight: "bold",
            }}
            value={formData.shippingSelected}
            onChange={(e) => {
              onShippingSelected(parseInt(e.target.value), "melhorenvio");
            }}
            disabled={formData.cep.length !== 8}
            style={changeWidthInput(currentScreen, "100%")}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );

  const renderFinalPrice = () => (
    <div className={styles.boxInputs}>
      <div className={styles.boxFinalPrice}>
        <p>Preço Final:</p>
        <span>{Filters.convertMoneyTextMask(calculateTotalPrice())}</span>
        <span>
          Preço do produto {Filters.convertMoneyTextMask(product?.price)} +
          Preço da entrega:{" "}
          {Filters.convertMoneyTextMask(
            (shippingPrice?.vlrFrete || shippingPrice?.price) ?? 0
          )}
        </span>
      </div>
      <div className={styles.formLine2}>
        <CustomButton
          onClick={() => onProceed()}
          style={changeWidthInput(currentScreen, "100%")}
        >
          Prosseguir para o pagamento
        </CustomButton>
      </div>
    </div>
  );

  return (
    <div className={styles.contentDetails}>
      <div className={styles.titleProduct}>
        <h1>{product?.name}</h1>
        <div>
          <span>Preço:</span>
          <p>{Filters.convertMoneyTextMask(product?.price)}</p>
        </div>
      </div>
      <div className={styles.contentDescription}>
        <h1>Descrição do produto</h1>
        <p>{product?.description}</p>
        {renderProductOptions()}
      </div>
      <Divider />
      <div className={styles.contentForm}>
        {renderIdentificationForm()}
        {renderAddressForm()}
        {renderFinalPrice()}
      </div>
    </div>
  );
};

export default SellingInformation;
