import api from "../api";

export async function getUsers() {
  return new Promise((resolve, reject) => {
    api
      .get("user/admin")
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function updateSimpleUser(
  userId: string,
  payload: any
): Promise<any> {
  return await api.put("/user" + userId, payload);
}

export async function getUserById(userId: string): Promise<any> {
  return await api.get("/user/user/" + userId);
}
