import React from "react";
import { InputTextSimple } from "../../../../components/inputs/simpleText/inputSimpleText";
import { InputSimpleSelect } from "../../../../components/inputs/simpleSelect/simpleSelectInput";
import styles from "../styleUsers.module.scss";

const FiltersComponent = ({ filterOptions, activeOptions, filterBy, filter, setFilterBy, setFilter }) => (
  <div>
    <div style={{ marginBottom: 5 }}>Filtrar por:</div>
    <div className={styles.row}>
      <InputSimpleSelect
        disableOptionZero
        data={filterOptions}
        onChange={(e) => {
          setFilter("");
          setFilterBy(e.target.value);
        }}
        value={filterBy}
      />
      {filterBy === "active" ? (
        <InputSimpleSelect
          disableOptionZero
          data={activeOptions}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          value={filter}
        />
      ) : (
        <InputTextSimple
          name="filter"
          value={filter}
          placeholder="Pesquisar..."
          onChange={(e) => setFilter(e.target.value)}
        />
      )}
    </div>
  </div>
);

export default FiltersComponent;
