import api from "../api";

const notifications = async (userId: string) => {
  return await api.get("/notification/user?id=" + userId);
};

const readNotifications = async (notificationId: string) => {
  return await api.post("/notification/read?id=" + notificationId);
};

export { notifications, readNotifications };
