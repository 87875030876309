import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Button, Result } from "antd";

import PrivateWrapper from "./PrivateWrapper";
import Login from "../pages/Auth/Login";
import Dashboard from "../pages/Dashboard";
import NotFound from "../pages/NotFound";
import Career from "../pages/Career";
import { PageProductsDetails } from "../pages/ProductsDetails/productsDetails";
import Layout from "../components/layout";
import AdminLayout from "../components/adminLayout";
import { PageProducts } from "../pages/Products/pageProducts";
import { StructurePage } from "../pages/Structures/structurePage";
import AdminWrapper from "./AdminWrapper";
import AdminProducts from "../pages/Admin/Products";
import AddProducts from "../pages/Admin/Products/add";
import AdminUsers from "../pages/Admin/Users";
import { Invitation } from "../pages/Invitation";
import AdminDocuments from "../pages/Admin/Documents";
import AddDocuments from "../pages/Admin/Documents/add";
import { Financial } from "../pages/Financial";
import { Help } from "../pages/Help";
import AgentProfile from "../pages/AgentProfile";
import { PageDocuments } from "../pages/Documents/pageDocuments";
import DashboardAdmin from "../pages/DashboardAdmin";
import SalesReports from "../pages/Admin/sales";
import RecoverPass from "../pages/Auth/RecoverPass";
import EmailConfirmation from "../pages/EmailConfirmation";
import MasterWrapper from "./MasterWrapper";
import MasterLayout from "../components/masterLayout";
import DashboardMaster from "../pages/DashboardMaster";
import Checkout from "../pages/Checkout";
import CheckoutFeedBack from "../pages/Checkout/feedBack";

const RootRoutes: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route element={<PrivateWrapper />}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="structure" element={<StructurePage />} />
          <Route path="career" element={<Career />} />
          <Route path="financial" element={<Financial />} />
          <Route path="products" element={<PageProducts />} />
          <Route path="agent-profile" element={<AgentProfile />} />
          <Route path="help" element={<Help />} />
          <Route path="documents" element={<PageDocuments />} />
        </Route>
        <Route element={<AdminWrapper />}>
          <Route path="/admin/" element={<AdminLayout />}>
            <Route index element={<DashboardAdmin />} />
            <Route path="products">
              <Route index element={<AdminProducts />} />
              <Route path="add" element={<AddProducts />} />
              <Route path="add/:productId" element={<AddProducts />} />
            </Route>
          </Route>
        </Route>
        <Route element={<MasterWrapper />}>
          <Route path="/master/" element={<MasterLayout />}>
            <Route index element={<DashboardMaster />} />
            <Route path="products">
              <Route index element={<AdminProducts />} />
              <Route path="add" element={<AddProducts />} />
              <Route path="add/:productId" element={<AddProducts />} />
            </Route>
            <Route path="documents">
              <Route index element={<AdminDocuments />} />
              <Route path="add" element={<AddDocuments />} />
              <Route path="edit/" element={<AddDocuments />} />
            </Route>
            <Route path="sales" element={<SalesReports />} />
            <Route path="users" element={<AdminUsers />} />
          </Route>
        </Route>
      </Route>

      <Route path="/login" element={<Login />} />
      <Route path="/active-account" element={<EmailConfirmation />} />
      <Route path="/recover-pass" element={<RecoverPass />} />
      <Route path="/invitation/:userId" element={<Invitation />} />
      <Route path="/checkout/:productId" element={<Checkout />} />
      <Route
        path="/checkout/feedback/:orderId"
        element={<CheckoutFeedBack />}
      />

      <Route
        path="/unauthorized"
        element={
          <Result
            status="403"
            title="403"
            subTitle="Desculpe, você não está autorizado a acessar essa pagina!"
            extra={
              <Button type="primary" onClick={() => navigate("/")}>
                Voltar
              </Button>
            }
          />
        }
      />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
};

export default RootRoutes;
