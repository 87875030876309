import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { PenSquare, Forward, Download } from "lucide-react";
import { Button, Divider, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { saveAs } from "file-saver";

import Filters from "../../../../libs/Filters";
import { InputTextSimple } from "../../../../components/inputs/simpleText/inputSimpleText";
import { ContextApi } from "../../../../contexts";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import styles from "../styleUsers.module.scss";
import { useExtractChildren } from "../../../../hooks/useExtractChildren";
import {
  getSellingReports,
  getSellingReportsFile,
} from "../../../../services/requests/commissions";

interface UsersInfoTypes {
  currentUser: any;
  email: string;
  setEmail: any;
  fetchDataForCurrentUser: any;
  apiData: any;
}

const UsersInfoModal: React.FC<UsersInfoTypes> = ({
  currentUser,
  email,
  setEmail,
  fetchDataForCurrentUser,
  apiData,
}) => {
  const {
    dimensions,
    transactions,
    getAllUsers,
    spheresResp,
    getFiles,
    files,
  } = useContext(ContextApi);
  const [sellingData, setSellingData] = useState<any>({});
  const [edit, setEdit] = useState(false);

  const children = useExtractChildren(spheresResp?.rootNode);

  const updateUser = async () => {
    if (currentUser) {
      await api
        .put(`user/${currentUser?._id}`, {
          email: email,
        })
        .then(() => {
          toast.success("Email alterado com sucesso");
          getAllUsers();
        })
        .catch(() => {
          toast.error("Erro ao alterar o email");
        })
        .finally(() => fetchDataForCurrentUser(currentUser._id));
    }
  };

  const _fetch = async () => {
    setSellingData(null);
    try {
      const { data } = await getSellingReports(currentUser?._id);
      setSellingData(data);
    } catch (error: any) {
      setSellingData(null);
    }
  };

  const downloadPdf = async () => {
    try {
      const response = await getSellingReportsFile(currentUser?._id, "pdf");
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      saveAs(
        pdfBlob,
        `${currentUser?.name?.trim()}-${moment().format("L")}-vendas`
      );
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    _fetch();
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      getFiles("avatar", currentUser?._id ?? "");
    }
  }, [currentUser]);

  const avatar = files.find((f) => f.fieldId === currentUser?._id) ?? null;
  const avatarDefault = "https://picsum.photos/200";

  const columns: ColumnsType<any> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Valor da venda",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalTitle}>Detalhes</div>
      <div className={styles.profileContainer}>
        <div className={styles.profileContainerBg}>
          <img
            alt="perfil"
            className={styles.profile}
            src={avatar?.path ?? avatarDefault}
          ></img>
        </div>
      </div>
      <div
        style={{ width: dimensions.width > 768 ? "75%" : "100%" }}
        className={styles.data}
      >
        <div>
          <div className={styles.dataTitle}>Nome</div>
          <div className={styles.dataValue}>{currentUser?.name}</div>
        </div>
        <div className={styles.rowModal}>
          <div>
            <div className={styles.dataTitle}>Telefone</div>
            <div className={styles.dataValue}>
              {Filters.inputMaskTELWithDDD(currentUser?.phone)}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {edit ? (
              <InputTextSimple
                name="email"
                placeholder="E-mail"
                value={email}
                onChange={(e) => {
                  if (e) setEmail(e.target.value);
                }}
              />
            ) : (
              <>
                <div className={styles.dataTitle}>E-mail:</div>
                <div className={styles.dataValue}>{email}</div>
              </>
            )}
            <button
              className={styles.editButton}
              onClick={() => setEdit(!edit)}
            >
              {edit ? <Forward onClick={() => updateUser()} /> : <PenSquare />}
            </button>
          </div>
        </div>
        <div>
          <div className={styles.dataTitle}>Endereço</div>
          <div className={styles.row}>
            <div className={styles.dataValue}>
              {`${currentUser?.address?.street} ${
                currentUser?.address?.number
              } ${currentUser?.address?.city} ${
                currentUser?.address?.state
              } ${Filters.inputMaskCEP(currentUser?.address?.postalCode)}`}
            </div>
          </div>
        </div>
        <div className={styles.rowModal}>
          <div>
            <div className={styles.dataTitle}>Graduação</div>
            <div className={styles.dataValue}>
              <div style={{ textTransform: "capitalize" }}>
                {currentUser?.graduation?.toLowerCase()}
              </div>
            </div>
          </div>
          <div>
            <div className={styles.dataTitle}>Total de vendas</div>
            <div className={styles.dataValue}>{transactions.length}</div>
          </div>
          <div>
            <div className={styles.dataTitle}>Grupo</div>
            <div className={styles.dataValue}>{children?.length} Pessoas</div>
          </div>
        </div>
        <div>
          <div>
            <div className={styles.dataTitle}>Saldo disponível</div>
            <div className={styles.dataValue}>
              {Filters.convertMoneyTextMask(apiData?.userBalance?.money)}
            </div>
          </div>
          <div className={styles.dataTitle}>Ultimo acesso</div>
          <div>
            {currentUser?.lastAccess
              ? moment(currentUser?.lastAccess?.toString()).format("LLLL")
              : "-"}
          </div>
        </div>
        <Divider />

        {sellingData?.compradores && (
          <div>
            <div className={styles.modalTitle}>
              Todas as vendas
              <Button
                type="primary"
                icon={<Download size={18} />}
                size="middle"
                style={{ marginLeft: 10 }}
                onClick={() => downloadPdf()}
              />
            </div>
            <br />
            <Table columns={columns} dataSource={sellingData.compradores} />
          </div>
        )}
      </div>
    </div>
  );
};

export default UsersInfoModal;
