import { Hammer, LogOut, Menu } from "lucide-react";
import styles from "./styleNavbar.module.scss";
import { ContextApi } from "../../../../contexts";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import WorkersAndCareerModal from "../../../WorkersAndCareer/WorkersAndCareerModal";

export default function Navbar() {
  const { logoutRequest, drawerOpen, setDrawerOpen } = useContext(ContextApi);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const toggleWorkerStatus = (workerId: string) => {
    // Implementar lógica de ativar/desativar trabalhador
    console.log(`Toggling status for worker ${workerId}`);
  };

  const navigate = useNavigate();

  return (
    <div className={styles.background}>
      <div className={styles.navbar}>
        <div className={styles.leftContainer}>
          <div className={styles.menu}>
            <Menu
              onClick={() => setDrawerOpen(!drawerOpen)}
              className={styles.icon}
            />
          </div>
          <div className={styles.logo} onClick={() => navigate("/admin")}>
            <h4>
              Plano<span>NPAC</span>
            </h4>
          </div>
        </div>
        <div className={styles.actions}>
          <Hammer className={styles.icon} onClick={showModal} />
          <div className={styles.divider} />
          <LogOut onClick={logoutRequest} className={styles.icon} />
        </div>
      </div>
      <WorkersAndCareerModal
        open={open}
        onClose={handleCancel}
        toggleWorkerStatus={toggleWorkerStatus}
      />
    </div>
  );
}
