import React, { useContext, useEffect, useState, useTransition } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Spin } from "antd";

import ProductDetailsHeader from "./domain/CheckoutDetailsHeader";
import ProductImageGallery from "./domain/ProductImageGallery";
import ProductDetailsContent from "./domain/SellIingnformation";
import PaymentComponent from "./domain/Payment";
import { ContextApi } from "../../contexts";
import { getProductById } from "../../services/requests/products";
import { setProduct } from "../../redux/products";
import { initialState } from "./domain/contants";
import { ShippingCost } from "./domain/types";
import formatData from "./domain/formatter/formatterForApi";
import { formatDataForApi } from "./domain/Formatters";
import { processCheckout } from "../../services/requests/transactions";
import styles from "./styles.module.scss";

const Checkout = () => {
  const {
    getAdressByPostalCode,
    adress,
    getAllStates,
    ufs,
    getCitiesByUf,
    cities,
    getShippingCost,
    shippingCostResponse,
  } = useContext(ContextApi);

  const productFiltered = useSelector((state: any) => state.products.value);
  const { productId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [nextIndex, setNextIndex] = useState(0);
  const [step, setStep] = useState<"user" | "payment">("user");
  const [shippingPrice, setShippingPrice] = useState<Partial<ShippingCost>>({});
  const [loading, setLoading] = useState(true);
  const [orderId, setOrderId] = useState<string | undefined>("");
  const [formData, setFormData] = useState(initialState);
  const [cardDetails, setCardDetails] = useState({
    creditCardNumber: "",
    expirationDate: "",
    cardHolderName: "",
    cvc: "",
  });
  const [paymentMethod, setPaymentMethod] = useState<"pix" | "creditCard" | "">(
    ""
  );
  const [feedBack, setFeedBack] = useState("processing");
  const [errorMessage, setErrorMessage] = useState("error");

  const [installments, setInstallments] = useState(1);
  const [isPending, startTransition] = useTransition();

  const decoderProductId = atob(productId as string);
  const currentScreen = window.innerWidth;

  useEffect(() => {
    if (adress?.uf) {
      setStatesAndCitiesByAdress();
    }
  }, [adress, cities]);

  useEffect(() => {
    if (step === "user") {
      setOrderId(undefined);
    }
  }, [step]);

  useEffect(() => {
    startTransition(() => {
      fetchProductData(JSON.parse(decoderProductId)?.productId as string);
      getAllStates();
    });
    setTimeout(() => setLoading(false), 1000);
  }, [productId, getAllStates]);

  const changeWidthInput = (value: number, defaultWidth: string) => {
    const widthMap = {
      "49%": value < 1024 && value > 557,
      "99%": value <= 557,
      [defaultWidth]: true,
    };

    const width =
      Object.keys(widthMap).find((key) => widthMap[key]) || defaultWidth;

    return {
      width,
      marginBottom: "10px",
    };
  };

  const setStatesAndCitiesByAdress = () => {
    setLoading(true);
    const state = ufs?.find((item) => item.sigla === adress?.uf);
    const city = cities?.find((item) => item.nome === adress?.localidade);
    setFormData((prevData) => ({
      ...prevData,
      stateSelected: state?.id || 0,
      citiesSelected: city?.id || 0,
      bairro: adress?.bairro || "",
      logradouro: adress?.logradouro || "",
    }));
    setTimeout(() => setLoading(false), 2000);
  };

  const calcShipping = (cepString: string) => {
    getShippingCost({
      sCepOrigem: "88330528",
      sCepDestino: cepString,
      products: [
        {
          id: productFiltered?._id,
          width: productFiltered?.shippingValues?.width,
          height: productFiltered?.shippingValues?.height,
          length: productFiltered?.shippingValues?.length,
          weight: productFiltered?.shippingValues?.weight,
          insurance_value: productFiltered.price,
          quantity: 1,
        },
      ],
    });
  };

  const fetchProductData = (id: string) => {
    getProductById(id)
      .then((response: any) => {
        const data = response?.data;
        const payload = {
          ...data?.product,
          imageUrls: [...data?.imageUrls],
        };
        dispatch(setProduct(payload));
      })
      .catch(() => {
        toast.error("Falha ao carregar produtos!");
      });
  };
  const proceedToCheckout = async () => {
    setLoading(true);

    try {
      const payload = formatData(
        formData,
        cardDetails,
        paymentMethod || "pix",
        installments,
        productFiltered,
        shippingPrice?.price || 0
      );
      const res = await processCheckout(payload, productId);
      setOrderId(res.id);

      if (res?.id) {
        navigate("/checkout/feedback/" + res?.id);
      }
    } catch (error: any) {
      setFeedBack("error");

      // Verifique se o erro tem a estrutura esperada
      if (error?.response && error?.response?.data) {
        const errorsObject = error?.response?.data?.errors || {};
        const errorMessages = Object.keys(errorsObject).map((key) => {
          return errorsObject[key].join(" ");
        });
        setErrorMessage(errorMessages.join(", "));
      } else {
        setErrorMessage("Ocorreu um erro inesperado. Tente novamente.");
      }

      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const validateUserAndDeliveryInfos = () => {
    setLoading(true);
    try {
      const payload = formatDataForApi(formData, productFiltered, adress);
      if (payload) {
        setStep("payment");
      }
    } catch (error: any) {
      console.error("Erro ao processar a compra:", error);
      if (error.message) {
        toast.error(`Erro: ${error.message}`);
      } else {
        toast.error(
          "Erro ao processar a compra. Detalhes do erro foram registrados no console."
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <ProductDetailsHeader title="Plano NPAC" />
        <div className={styles.containerDetails}>
          {step === "user" ? (
            <UserStep
              productFiltered={productFiltered}
              formData={formData}
              setFormData={setFormData}
              currentScreen={currentScreen}
              changeWidthInput={changeWidthInput}
              getAdressByPostalCode={getAdressByPostalCode}
              calcShipping={calcShipping}
              shippingCostResponse={shippingCostResponse}
              ufs={ufs}
              getCitiesByUf={getCitiesByUf}
              cities={cities}
              validateUserAndDeliveryInfos={validateUserAndDeliveryInfos}
              shippingPrice={shippingPrice}
              setShippingPrice={setShippingPrice}
              nextIndex={nextIndex}
              setNextIndex={setNextIndex}
            />
          ) : (
            <PaymentComponent
              currentScreen={currentScreen}
              changeWidthInput={changeWidthInput}
              productInfo={productFiltered}
              shippingPrice={shippingPrice}
              setStep={setStep}
              cardDetails={cardDetails}
              setCardDetails={setCardDetails}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              installments={installments}
              setInstallments={setInstallments}
              proceed={proceedToCheckout}
              orderId={orderId}
              loading={setLoading}
              feedback={feedBack}
              setFeedback={setFeedBack}
              errorsMessage={errorMessage}
            />
          )}
          <Spin spinning={loading || isPending} fullscreen />
        </div>
      </div>
    </div>
  );
};

const UserStep: React.FC<any> = ({
  productFiltered,
  formData,
  setFormData,
  currentScreen,
  changeWidthInput,
  getAdressByPostalCode,
  calcShipping,
  shippingCostResponse,
  ufs,
  getCitiesByUf,
  cities,
  validateUserAndDeliveryInfos,
  shippingPrice,
  setShippingPrice,
  nextIndex,
  setNextIndex,
}) => (
  <>
    <ProductImageGallery
      imgProduct={productFiltered?.imageUrls ?? []}
      nextIndex={nextIndex}
      setNextIndex={setNextIndex}
    />
    <ProductDetailsContent
      product={productFiltered}
      formData={formData}
      currentScreen={currentScreen}
      changeWidthInput={changeWidthInput}
      onOptionsUpdate={(data) => {
        const citiesSelected = Number(data?.citiesSelected) || 0;
        setFormData((prevData: any) => ({
          ...prevData,
          ...data,
          citiesSelected,
        }));
      }}
      getAdressByPostalCode={getAdressByPostalCode}
      calcShipping={calcShipping}
      shipingCost={shippingCostResponse}
      ufs={ufs}
      getCitiesByUf={getCitiesByUf}
      cities={cities}
      onProceed={validateUserAndDeliveryInfos}
      saleIdentification={productFiltered?._id ?? ""}
      shippingPrice={shippingPrice}
      setShippingPrice={setShippingPrice}
    />
  </>
);

export default Checkout;
