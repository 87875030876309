import React, { ChangeEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { Lock, Eye, EyeOff, User } from "lucide-react";
import { toast } from "react-toastify";
import { LoginLayout } from "../../../components/loginLayout";
import styles from "./recover.module.scss";
import api from "../../../services/api";
interface SendRecoverProps {
  recoverEmail: string;
  setRecoverEmail: (value: string) => void;
  onSubmit: (event: ChangeEvent<HTMLFormElement>) => void;
}

interface ChangePassProps {
  newPassword: string;
  checkPassword: string;
  lookPassword: boolean;
  setNewPassword: (value: string) => void;
  setCheckPassword: (value: string) => void;
  setLookPassword: (value: boolean) => void;
  onSubmit: (event: ChangeEvent<HTMLFormElement>) => void;
}

function RecoverPass(): JSX.Element {
  const [newPassword, setNewPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [recoverEmail, setRecoverEmail] = useState("");
  const [lookPassword, setLookPassword] = useState(false);

  const { search } = useLocation();
  const { token } = queryString.parse(search);
  const { email } = queryString.parse(search);
  const navigate = useNavigate();

  async function passChange(event: ChangeEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!newPassword) {
      return toast.warning("Preencha sua senha");
    }

    if (newPassword !== checkPassword) {
      return toast.error("As senhas são diferentes, tente novamente");
    }

    try {
      await api.post("/auth/change-pass", {
        password: newPassword,
        token: token,
        email: email,
      });

      toast.success(
        "Senha alterada, você será redirecionado para a página de login em instantes..."
      );

      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      toast.error(
        "Não foi possível trocar sua senha, contate seu administrador do site."
      );
    }
  }

  const requestEmail = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();

    await api
      .post("/auth/recover", {
        email: recoverEmail,
      })
      .then(() =>
        toast.success("Verifique sua caixa de email, para prosseguir")
      )
      .catch(() =>
        toast.error(
          "Erro ao enviar email, tente novamente, se o erro persistir contate o suporte"
        )
      );
  };

  return (
    <LoginLayout>
      <div className={styles.pageLoginRegistrationForm}>
        {token ? (
          <ChangePass
            newPassword={newPassword}
            checkPassword={checkPassword}
            lookPassword={lookPassword}
            setNewPassword={setNewPassword}
            setCheckPassword={setCheckPassword}
            setLookPassword={setLookPassword}
            onSubmit={passChange}
          />
        ) : (
          <SendRecover
            recoverEmail={recoverEmail}
            setRecoverEmail={setRecoverEmail}
            onSubmit={requestEmail}
          />
        )}
      </div>
    </LoginLayout>
  );
}

const ChangePass: React.FC<ChangePassProps> = ({
  newPassword,
  checkPassword,
  lookPassword,
  setNewPassword,
  setCheckPassword,
  setLookPassword,
  onSubmit,
}) => (
  <form onSubmit={onSubmit}>
    <h2>Digite uma nova senha</h2>
    <div className={styles.pageLoginRegistrationFormInput}>
      <Lock
        className={styles.pageLoginRegistrationInputIcon}
        size={20}
        color="#AEAEAE"
      />
      <input
        type={lookPassword ? "text" : "password"}
        placeholder="Nova senha"
        onChange={(e) => setNewPassword(e.target.value)}
        value={newPassword}
        name="newPassword"
      />

      <button type="button" onClick={() => setLookPassword(!lookPassword)}>
        {lookPassword ? (
          <EyeOff size={20} color="#AEAEAE" />
        ) : (
          <Eye size={20} color="#AEAEAE" />
        )}
      </button>
    </div>
    <div className={styles.pageLoginRegistrationFormInput}>
      <Lock
        className={styles.pageLoginRegistrationInputIcon}
        size={20}
        color="#AEAEAE"
      />
      <input
        type={lookPassword ? "text" : "password"}
        placeholder="Digite novamente sua senha"
        onChange={(e) => setCheckPassword(e.target.value)}
        value={checkPassword}
        name="checkPassword"
      />

      <button type="button" onClick={() => setLookPassword(!lookPassword)}>
        {lookPassword ? (
          <EyeOff size={20} color="#AEAEAE" />
        ) : (
          <Eye size={20} color="#AEAEAE" />
        )}
      </button>
    </div>
    <button type="submit">Trocar a senha</button>
  </form>
);

const SendRecover: React.FC<SendRecoverProps> = ({
  recoverEmail,
  setRecoverEmail,
  onSubmit,
}) => (
  <form onSubmit={onSubmit}>
    <h2>Digite seu e-mail</h2>
    <div className={styles.pageLoginRegistrationFormInput}>
      <User
        className={styles.pageLoginRegistrationInputIcon}
        size={20}
        color="#AEAEAE"
      />
      <input
        type="email"
        placeholder="Email"
        onChange={(e) => setRecoverEmail(e.target.value)}
        value={recoverEmail}
        name="email"
      />
    </div>

    <button type="submit">Enviar</button>
  </form>
);

export default RecoverPass;
